<mat-card class="expense-card">
    
    <mat-card-title style="color:white; background-color: #0B0C12; padding:2%;">Purchase</mat-card-title>
       
    <mat-card-content>   
            
        <form [formGroup]="purchaseForm" (ngSubmit)="doPurTransaction()">

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1%" style="padding:2%;">

                <div> <app-correct-date [title]="'Purchase Date'" formControlName="purchaseDate"></app-correct-date></div>
                
                <div fxLayout="row" fxLayoutGap="1%">

                    <mat-form-field class="beneficiary" appearance="outline" fxFlex="92%">
                        <mat-label>Select Creditor</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto" formControlName="creditorName">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.cred_name">
                                    {{option.cred_name}}
                                </mat-option>
                            </mat-autocomplete>
                    </mat-form-field>

                    <button type="button" class="custbutton" fxFlex="8%" (click)="createNewCreditor()">
                        <i class="fas fa-plus"></i>
                    </button>

                </div>

                <div fxLayout="row" fxLayoutGap="1%">

                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Purchase Item</mat-label>
                        <mat-select formControlName="purchaseItem">
                            <mat-option *ngFor="let item of purchase_item" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                        
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Purchase Of</mat-label>
                        <mat-select formControlName="purchaseOf">
                            <mat-option *ngFor="let choice of purchase_of" [value]="choice">
                                {{choice}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                
                </div>

                <mat-form-field class="amtSpent" appearance="outline">
                    <mat-label>Purchase Amount</mat-label>
                    <input type="text" matInput formControlName="purchaseAmount">
                </mat-form-field>

                <mat-form-field class="remarks" appearance="outline">
                    <mat-label>Purchase Remarks</mat-label>
                    <input type="text" matInput formControlName="purchaseRemark">
                </mat-form-field>
                    
                <div fxLayout="row" fxLayoutAlign="space-around center">
                        
                    <button type="submit" mat-raised-button color="warn" class="submitbutton" [disabled]="!purchaseForm.valid"> Submit </button>
                    <button type="button" mat-raised-button color="warn" class="submitbutton" (click)="onReset()"> Reset Fields </button>

                </div>

            </div>

        </form>

    </mat-card-content>

</mat-card>