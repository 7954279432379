import { Component, OnInit, ViewChild} from '@angular/core';
import { ApiService } from '../services/api.service';
import {Cheques, ChequesOld} from '../Modules/tables'
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS} from 'date.adapter';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import { ChequedialogComponent } from '../chequedialog/chequedialog.component';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-cheques-due',
  templateUrl: './cheques-due.component.html',
  styleUrls: ['./cheques-due.component.css'],
  providers: [DatePipe,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})

export class ChequesDueComponent implements OnInit {

  goback: boolean;
  uncashed: boolean;
  cheques: Cheques[];
  oldcheques: Cheques[];

  state = [];
  i:number;
  selectedId: number;

  dataSource = new MatTableDataSource<Cheques>();
  dataSource2 = new MatTableDataSource<ChequesOld>();
  private paginator: MatPaginator;

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
    }

    setDataSourceAttributes() {
      this.dataSource2.paginator = this.paginator;
    }

  displayedColumns: string[] = ['select', 'serial', 'cd_custname', 'cd_amount', 'cd_issuedate', 'cd_cashoutdate','cstatus','cheque_of','cd_cheque_type',
                                  'cd_issuename','cd_foraccount','cashout'
  ];
  
  displayedColumns2: string[] = ['serial', 'cd_custname', 'cd_amount', 'cd_issuedate', 'cd_cashoutdate', 'cd_witdate','cstatus','cheque_of','cd_cheque_type',
                                    'cd_issuename','cd_foraccount'
  ];

  constructor(private service: ApiService, private datepipe: DatePipe, public dialog: MatDialog) { }

  ngOnInit() {

    for (let i = 0; i < 50; i++) {
      this.state[i] = true;
    }

    this.service.showCheques().subscribe((cheques: Cheques[])=>{
      this.cheques = cheques;
      this.dataSource.data = this.cheques;
    })

    this.service.oldCheques().subscribe((oldchques:Cheques[])=>{
      this.oldcheques = oldchques;
      this.dataSource2.data = this.oldcheques;
    })
    
    this.goback    = true;
    this.uncashed  = false;
  }

  selection = new SelectionModel<Cheques>(true, []);

  togglebutton(row) { 
    this.i = row.position;
    this.state[this.i] = !this.state[this.i];
  }

  checkboxLabel(row?: Cheques): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position}`;
  }

  chequeCashed(row){
    this.selectedId = row.cd_id;
    this.openDialog();
    this.service.getChequeId(this.selectedId);    // Sending selected cheque row id to a subject in service where that subjec can send the id to cheque cash dialog
  }

  openDialog(){
    this.dialog.open(ChequedialogComponent);
  }

  showOldCheques(){
    this.goback    = false;
    this.uncashed  = true;
  }

  goBack(){
    this.goback    = true;
    this.uncashed  = false;
  }

}
