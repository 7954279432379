<mat-card style="height:100%">

    <mat-card-title style="color:white; background-color: #0B0C12; padding:2%;">Cash Deposit</mat-card-title>         
          
    <mat-card-content>        
    
        <form [formGroup]="depositsForm" (ngSubmit)="doCDTransaction()">

            
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="2%" style="padding:2%;">
            
                <app-correct-date [title]="'Select Date'" formControlName="depositDate"></app-correct-date>
                
                <mat-form-field class="amtDep" appearance="outline">
                    <mat-label> Amount Deposited </mat-label>
                    <input type="text" matInput formControlName="amountDeposited">
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="1%">

                    <mat-form-field class="customer" appearance="outline" fxFlex="92%">
                        <mat-label>Select Bank A/C</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto" formControlName="bankAccount">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of bankAccounts | async" [value]="option">
                            {{option.bank_name }} || {{ option.bank_acholder}}
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    
                    <button type="button" class="custbutton" (click)="createBL()" fxFlex="8%" >
                        <i class="fas fa-plus"></i>
                    </button>

                </div>
                
                <div fxLayout="row" fxLayoutAlign="space-between end">

                <button type="submit" mat-raised-button class="submitbutton" > Submit </button>
                <button type="button" mat-raised-button class="submitbutton" (click)="onReset()"> Reset Fields </button>

                </div>
            
            </div>

        </form>

    </mat-card-content>
    
</mat-card>