import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CDTransaction, filterdate } from '../Modules/tables'
import { DaybookService } from '../services/daybook.service';

@Component({
  selector: 'app-deposits-report',
  templateUrl: './deposits-report.component.html',
  styleUrls: ['./deposits-report.component.css']
})
export class DepositsReportComponent implements OnInit {

  constructor(private service: DaybookService, private datepipe: DatePipe) { }  

ngOnInit() {
  this.service.events$.forEach(event => this.catchEvent(event));
}
catchEvent(event){
  this.filterTransactionByDate(event);
}
filterDate: filterdate[];
filteredParentDate: Date;
nextevent: Event;
filteredData : CDTransaction[];
transactions: CDTransaction[];

displayedColumns: string[] = ['serial', 'dep_date', 'dep_amount', 'bank_account'];

childDate: Date;
date: string;

filterTransactionByDate(event) {
  this.service.filteredDate.subscribe(childDate => this.childDate = childDate);
  console.log(this.childDate);
  this.filteredParentDate = this.childDate;
  this.date = this.datepipe.transform(this.filteredParentDate, 'yyyy-MM-dd');
  console.log(this.date);
  this.service.readDeposit(this.date).subscribe((transactions: CDTransaction[])=>{
    this.transactions = transactions;
    console.log(this.transactions);})
}



}
