//import {routingComponents} from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule} from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SalesComponent } from './sales/sales.component';
import { IncomeComponent } from './income/income.component';
import { DaybookComponent } from './daybook/daybook.component';
import { CreatecustComponent } from './createcust/createcust.component';
import { SalesreportComponent } from './salesreport/salesreport.component';
import { ExpensereportComponent } from './expensereport/expensereport.component';
import { CashincomeComponent } from './cashincome/cashincome.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { DepositsComponent } from './deposits/deposits.component';
import { IncomeAndSalesComponent } from './income-and-sales/income-and-sales.component';
import { CreditorsLedgerComponent } from './creditors-ledger/creditors-ledger.component';
import { ChequesDueComponent } from './cheques-due/cheques-due.component';
import { FinReportComponent } from './fin-report/fin-report.component';
import { ChequeIncomeComponent } from './cheque-income/cheque-income.component';
import { TransfersIncomeComponent } from './transfers-income/transfers-income.component';
import { CustomerLedgersComponent } from './customer-ledgers/customer-ledgers.component';


import { INRCurrencyPipe} from './daybook/daybook.component';
import { ChequedialogComponent } from './chequedialog/chequedialog.component';
import { CreditDebit} from './customer-ledgers/customer-ledgers.component';
import { WithdrawlComponent } from './withdrawl/withdrawl.component';
import { WithdrawlIncomeComponent } from './withdrawl-income/withdrawl-income.component';
import { DepositsReportComponent } from './deposits-report/deposits-report.component';
import { InventoryComponent } from './inventory/inventory.component';
import { CustomersComponent } from './customers/customers.component';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CorrectDateComponent } from './correct-date/correct-date.component';

import { GoogleChartsModule } from 'angular-google-charts';
import { NgChartsModule } from 'ng2-charts';
import { PurchaseComponent } from './purchase/purchase.component';
import { PurchaseAndExpensesComponent } from './purchase-and-expenses/purchase-and-expenses.component';
import { DepositsAndWithdrawlComponent } from './deposits-and-withdrawl/deposits-and-withdrawl.component';
import { CreateCredComponent } from './create-cred/create-cred.component';
import { CreatebankledgerComponent } from './createbankledger/createbankledger.component';

import { ExcelService} from './services/excel.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BankledgerComponent } from './bankledger/bankledger.component';
import { LedgerCorrectionComponent } from './ledger-correction/ledger-correction.component';
import { CredLedgerCorrectionComponent } from './cred-ledger-correction/cred-ledger-correction.component';
import { SaapatComponent } from './saapat/saapat.component';
import { SaapatCorrectionComponent } from './saapat-correction/saapat-correction.component';

import { AlertComponent } from './alert/alert.component';
import { JwtInterceptor } from './LoginAndRegisterationService/jwt.interceptor';
import { ErrorInterceptor } from './LoginAndRegisterationService/error.interceptor';


@NgModule({
    declarations: [
//      routingComponents,
        AppComponent,
        MainComponent,
        SalesComponent,
        IncomeComponent,
        DaybookComponent,
        CreatecustComponent,
        INRCurrencyPipe,
        CashincomeComponent,
        SalesreportComponent,
        ExpensereportComponent,
        ExpensesComponent,
        DepositsComponent,
        IncomeAndSalesComponent,
        CreditorsLedgerComponent,
        ChequesDueComponent,
        FinReportComponent,
        ChequeIncomeComponent,
        TransfersIncomeComponent,
        CustomerLedgersComponent,
        ChequedialogComponent,
        CreditDebit,
        WithdrawlComponent,
        WithdrawlIncomeComponent,
        DepositsReportComponent,
        InventoryComponent,
        CustomersComponent,
        CorrectDateComponent,
        PurchaseComponent,
        PurchaseAndExpensesComponent,
        DepositsAndWithdrawlComponent,
        CreateCredComponent,
        CreatebankledgerComponent,
        BankledgerComponent,
        LedgerCorrectionComponent,
        CredLedgerCorrectionComponent,
        SaapatComponent,
        SaapatCorrectionComponent,
        AlertComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatInputModule,
        NgChartsModule,
        GoogleChartsModule,
        NgbModule,
        FontAwesomeModule,
    ],
    providers: [ExcelService, 
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ], 
    bootstrap: [AppComponent]
})

export class AppModule { }