import { Component, OnInit } from '@angular/core';
import { DatePipe} from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS} from 'date.adapter';
import { ApiService } from '../services/api.service';
import {Cheques} from '../Modules/tables';
import {MatDialog} from '@angular/material/dialog';
import { AccountService } from '../LoginAndRegisterationService/account.service';
import { faBook } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  providers: [DatePipe,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})

export class MainComponent implements OnInit  {

  faBook = faBook;

  loginbtn:boolean;
  logoutbtn:boolean;
  today = new Date();
  duedate: string;
  list: Cheques[];
  notification: number = 0;
  showNotification: boolean;
  isExpanded = false;
  element: HTMLElement;

  constructor(private service: ApiService, public dialog: MatDialog, private accountService: AccountService) { }

  logout(){
    window.location.href = window.location.href;
    this.accountService.logout();
  }

  ngOnInit() {

    this.service.getNotification().subscribe((due: number)=>{
      this.notification =  due;
    })  
    if (this.notification = 0){
      this.showNotification = true;
    }
    else {
      this.showNotification = false;
    }
  }

  toggleActive(event:any){
    debugger;
    event.preventDefault();
    if(this.element !== undefined){
      this.element.style.backgroundColor = "#2b2d2f";
    } 
    var target = event.currentTarget;
    target.style.backgroundColor = "#e51282";
    this.element = target;
  }
 
}