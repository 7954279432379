import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Cheques } from '../Modules/tables';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-chequedialog',
  templateUrl: './chequedialog.component.html',
  styleUrls: ['./chequedialog.component.css']
})
export class ChequedialogComponent implements OnInit {
  
  selectDate:Date;
  selectId: number;
  cheque: Cheques[];
  one: number = 1;

  constructor(private service: ApiService, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {

  }

  cashCheque(){                                                                 // Called in html to confirm cheque cash and use service to update
    this.service.userChequeId.subscribe(selectId => this.selectId = selectId);
    const selectedId = {
      cheque_id: this.selectId,
      date: this.selectDate,
    };
    this.service.cashOut(selectedId).subscribe( (cheque: Cheques[])=>{
      console.log(cheque);
      this.openSuccessSnackBar('Cheque has been cashed', 'Close');
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');}
    );
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    let er_snack = this.errorsnackBar.open(message, action,
      {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
    er_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  openSuccessSnackBar(message: string, action: string){
    let su_snack = this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
      
    });
    su_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/main/chequesdue')}); 
    console.log('It works!')
  }
}
