<div class="inv_main" fxLayout="column" fxLayoutGap="1%" style="padding:1%; background-color: #e5e5e5">

    <div class="inv_header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
        <img src="../../assets/inventory.png" style="height:64px; width:64px;">
        <h1 style="margin-left:1%; font-weight: bold; font-size:300%;">Inventory</h1>
    </div>

    <div class="MAIN COLUMNS" fxLayout="row" fxLayout.xs="column" fxLayoutGap=".5%">

        <div class="COLUMN 1" fxFlex="50" fxLayout="column" fxLayoutGap="1%">

            <div class="COLUMN HEADER" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1%" style=" background-color: white; border-radius: 5px; padding: 1%;">

                <div style="align-items: center;">
                    <h3>New Hira</h3>
                </div>

                <mat-form-field appearance="outline" style="width: 150px;" [ngStyle.lt-sm]="{'width.px':150}">
                    <mat-label [ngStyle.lt-sm]="{'font-size.px':12}">Select Brick</mat-label>
                    <mat-select (selectionChange)="FetchInventoryHira($event)">
                        <mat-option *ngFor="let brick of bricks" [value]="brick">
                            {{brick}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="COLUMN BODY" fxLayout="column" style=" background-color: white; border-radius: 5px; padding: 1%;" fxLayoutGap="1%">

                <div class="BRICK HEADER" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1%">

                    <div [hidden]="titleH">
                        <h4>{{brickNameH}} Inventory</h4>
                    </div>

                    <div [hidden]="titleH" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">

                        <mat-form-field appearance="outline" style="width: 150px;">
                            <mat-label>Add Items</mat-label>
                            <input matInput [(ngModel)]="addedH" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>

                        <button mat-raised-button color="primary" (click)="UpdateInventoryH()">Add</button>

                    </div>

                </div>
                
                <div class="TABLE">
                
                    <table mat-table [dataSource]="nhiudatasource" >
                        
                        <ng-container matColumnDef="serial">
                            <th mat-header-cell *matHeaderCellDef> S.No. </th>
                            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="inv_date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.inv_date | date:'dd/MM/yyyy'}} </td>
                        </ng-container>
                                
                        <ng-container matColumnDef="inv_sold">
                                <th mat-header-cell *matHeaderCellDef> Sold </th>
                                <td mat-cell *matCellDef="let element"> {{element.inv_sold}} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="inv_added">
                                <th mat-header-cell *matHeaderCellDef> Added </th>
                                <td mat-cell *matCellDef="let element"> {{element.inv_added}} </td>
                        </ng-container>
                                
                        <ng-container matColumnDef="inv_balance">
                                <th mat-header-cell *matHeaderCellDef> Balance </th>
                                <td mat-cell *matCellDef="let element"> {{element.inv_balance}} </td>
                        </ng-container>
                                
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                    </table>
                        
                    <mat-paginator style="margin-top: 1%; border:2px solid;" [pageSize]="5" showFirstLastButtons></mat-paginator>
                
                </div>

            </div>

        </div>

        <div class="COLUMN 2" fxFlex="50" fxLayout="column" fxLayoutGap="1%">

            <div class="COLUMN HEADER" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1%" style=" background-color: white; border-radius: 5px; padding: 1%;">
                
                <h3>Tata</h3>

                <mat-form-field appearance="outline" [ngStyle.lt-sm]="{'width.px':150}">
                    <mat-label [ngStyle.lt-sm]="{'font-size.px':12}">Select Letter</mat-label>
                    <mat-select (selectionChange)="FetchInventoryTata($event)">
                        <mat-option *ngFor="let brick of bricks" [value]="brick">
                            {{brick}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="COLUMN BODY" fxLayout="column" style=" background-color: white; border-radius: 5px; padding: 1%;" fxLayoutGap="1%">

                <div class="BRICK HEADER" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1%">

                    <div [hidden]="titleT">
                        <h4>{{brickNameT}} Inventory</h4>
                    </div>

                    <div class="ADD ITEMS" [hidden]="titleT" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">

                        <mat-form-field appearance="outline" style="width: 150px;">
                            <mat-label>Add Items</mat-label>
                            <input matInput [(ngModel)]="addedT" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>

                        <button mat-raised-button color="primary" (click)="UpdateInventoryT()">Add</button>

                    </div>

                </div>
        
                <div class="TABLE">
        
                    <table mat-table [dataSource]="tiudatasource" >
                    
                        <ng-container matColumnDef="serial">
                            <th mat-header-cell *matHeaderCellDef> S.No. </th>
                            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="inv_date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.inv_date | date:'dd/MM/yyyy'}} </td>
                        </ng-container>
                            
                        <ng-container matColumnDef="inv_sold">
                            <th mat-header-cell *matHeaderCellDef> Sold </th>
                            <td mat-cell *matCellDef="let element"> {{element.inv_sold}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="inv_added">
                            <th mat-header-cell *matHeaderCellDef> Added </th>
                            <td mat-cell *matCellDef="let element"> {{element.inv_added}} </td>
                        </ng-container>
                            
                        <ng-container matColumnDef="inv_balance">
                            <th mat-header-cell *matHeaderCellDef> Balance </th>
                            <td mat-cell *matCellDef="let element"> {{element.inv_balance}} </td>
                        </ng-container>
                            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                    </table>
                
                    <mat-paginator style="margin-top: 1%; border:2px solid;" [pageSize]="5" showFirstLastButtons></mat-paginator>
        
                </div>

            </div>

        </div>

    </div>

</div>