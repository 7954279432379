import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LedgerService } from '../services/ledger.service';
import {updateledger} from '../Modules/tables';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

export interface DialogData {
  name: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-cred-ledger-correction',
  templateUrl: './cred-ledger-correction.component.html',
  styleUrls: ['./cred-ledger-correction.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},],
})
export class CredLedgerCorrectionComponent implements OnInit {

  ngOnInit(): void {
  }

  constructor(
    public dialogRef: MatDialogRef<CredLedgerCorrectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private ls:LedgerService,
    private errorsnackBar: MatSnackBar, 
    private successsnackBar: MatSnackBar,
    private router: Router
  ) {}

  dc: string[] = ['dr','cr'];

  ledgercorrection = new FormGroup({
    name: new FormControl(this.data.name, Validators.required),
    date: new FormControl('', Validators.required),
    part: new FormControl('', Validators.required),
    amount: new FormControl('', Validators.required),
    dc: new FormControl('', Validators.required),
  });

  editLedger(){
    this.ls.updateCredLedger(this.ledgercorrection.value).subscribe((ul:updateledger)=>{
      this.openSuccessSnackBar('Success! Ledger is updated. [Please open ledger again to see reflection in changes.]', 'Close')
    },  
    (error) => { this.openErrorSnackBar('Error occured. Ledger update not complete.','Close');},)
    this.onReset();
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    let er_snack = this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
    er_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }
  openSuccessSnackBar(message: string, action: string){
    let su_snack = this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
    su_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/main/creditorsledger')}); 
    console.log('It works!')
  }

  onReset(){
    this.ledgercorrection.reset;
  }

}