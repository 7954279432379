import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustTableName,CredLedger, Creditor } from  '../Modules/tables';
import { creditor_name, } from  '../Modules/tables';
import { Observable } from  'rxjs';
import { map } from 'rxjs/operators';
import {updateledger} from '../Modules/tables';
import { Customer,CLedger, Saapat, SaapatLedger } from  '../Modules/tables';
import { customer_name } from  '../Modules/tables';


@Injectable({
  providedIn: 'root'
})

export class LedgerService {

  constructor(private httpClient: HttpClient) { }
    
  PHP_API_SERVER = "https://piyushaswani.com/backend";

      // Cust Ledger

      showCustLedger(ledgerJSON: CustTableName): Observable<CLedger[]> {
        return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/custledger/readLedger.php`, ledgerJSON);
        }

        ledgerBalance(ledgerJSON: CustTableName): Observable<number>  {
            return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/custledger/getbalance.php`, ledgerJSON);
        }
        
        getDefaulters(): Observable<Customer[]>{
            return this.httpClient.get<Customer[]>(`${this.PHP_API_SERVER}/api/custledger/readcustlist.php`).
            pipe(map(result => result.filter(filtered => filtered.cust_balance < 0 )));
        }

        getCustOf(CN: customer_name): Observable<any>{
            return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/custledger/getcustof.php`, CN);
        }

        showLedgerCustList(beginsWith:string): Observable<Customer[]>{
            return this.httpClient.get<Customer[]>(`${this.PHP_API_SERVER}/api/custledger/readcustlist.php`).
            pipe(map(result => result.filter(filtered => filtered.cust_name.startsWith(beginsWith))));
        }

    // Cust Ledger ENDS


  showCredLedger(ledgerJSON: CustTableName): Observable<CredLedger[]> {
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/credledger/readCredLedger.php`, ledgerJSON);
  }

  CredLedgerBalance(ledgerJSON: CustTableName): Observable<number>  {
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/credledger/getCreditorBalance.php`, ledgerJSON);
  }

  getCredOf(CN: creditor_name): Observable<any>{
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/credledger/getCredOf.php`, CN);
  }

  showLedgerCredList(beginsWith:string): Observable<Creditor[]>{
    return this.httpClient.get<Creditor[]>(`${this.PHP_API_SERVER}/api/credledger/readCredList.php`).
    pipe(map(result => result.filter(filtered => filtered.cred_name.startsWith(beginsWith))));
  }

  updateCustLedger(data:updateledger):Observable<updateledger>{
    return this.httpClient.post<updateledger>(`${this.PHP_API_SERVER}/api/custledger/updateledger.php`, data)
  }

  updateCredLedger(data:updateledger):Observable<updateledger>{
    return this.httpClient.post<updateledger>(`${this.PHP_API_SERVER}/api/credledger/updatecredledger.php`, data)
  }

  // Saapat 
  
  getSaapat(): Observable<Saapat[]> {
      return this.httpClient.get<Saapat[]>(`${this.PHP_API_SERVER}/api/saapat/saapat.php`)
  }

  readSaapatLedger(ledgerJSON: CustTableName): Observable<SaapatLedger[]>{
      return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/saapat/saapatledger.php`, ledgerJSON);
  }

  spLedgerBalance(ledgerJSON: CustTableName): Observable<number>{
      return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/saapat/spbalance.php`, ledgerJSON);
  }

  updateSaapatLedger(data:updateledger):Observable<updateledger>{
    return this.httpClient.post<updateledger>(`${this.PHP_API_SERVER}/api/saapat/updatesaapatledger.php`, data)
  }

}