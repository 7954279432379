import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort'
import {BankLedger} from '../Modules/tables'
import { Finservice } from '../services/finservice.service';
import { LedgerService } from '../services/ledger.service';
import { ChangeDetectorRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-bankledger',
  templateUrl: './bankledger.component.html',
  styleUrls: ['./bankledger.component.css']
})

export class BankledgerComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public Bdata: {bankName:string, achName: string},
    private Fin: Finservice, private LS: LedgerService, private _cdr: ChangeDetectorRef, private http: HttpClient, private actRoute: ActivatedRoute) {}

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.BankLedgerDataSource.paginator = this.paginator;
  }

  private paginator: MatPaginator
  
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.BankLedgerDataSource.sort = this.sort;
  }

  ngOnInit() {

    this.Fin.changebank.subscribe(results=>{
      this.bankLedger = results;
      this.BankLedgerDataSource.data = this.bankLedger;
    });

    this.bankName = this.Bdata.bankName;
    this.achName = this.Bdata.achName;
  }
  
  BankLedgerDataSource = new MatTableDataSource<BankLedger>();
  bankLedger: BankLedger[];
  bankName: string;
  achName: string;
  ledgerColumns: string[] = ['serial', 'bl_date', 'bl_part', 'bl_debit', 'bl_credit', 'bl_balance'];

}