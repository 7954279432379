<div fxLayout="column" fxLayoutGap="1%" style="padding:1%; background-color: #e5e5e5">

    <div class="header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
        <img src="../../assets/cheque.png" style="height:64px; width:64px;">
        <h1 style="margin-left:1%; font-weight: bold; font-size:300%;">Cheques Due</h1>
        <div>
            <button (click)="showOldCheques()" [hidden]="uncashed" mat-raised-button>Old Cashed Cheques</button>
            <button (click)="goBack()" [hidden]="goback" mat-raised-button>Go Back To Uncashed Cheques</button>
        </div>
    </div>

    <div style="background-color: white; border-radius: 5px; padding: 1%;">
        <h3 style="font-weight: bold;" [hidden]="uncashed">Uncashed Cheques</h3>
        <h3 style="font-weight: bold;" [hidden]="goback">Old Cashed Cheques</h3>
    </div>
    
    <div style="padding:1%; background-color: white; border-radius: 5px;" [hidden]="uncashed">

        <table mat-table [dataSource]="dataSource" >
    
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef> Select Cheque </th>
                <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="togglebutton(row)"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="serial">
                <th mat-header-cell *matHeaderCellDef> S.No. </th>
                <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
            </ng-container>
                    
            <ng-container matColumnDef="cd_custname">
                <th mat-header-cell *matHeaderCellDef> Customer Name </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_custname}} </td>
            </ng-container>

            <ng-container matColumnDef="cd_amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_amount  | INRCurrency}} </td>
            </ng-container>
                
            <ng-container matColumnDef="cd_issuedate">
                <th mat-header-cell *matHeaderCellDef> Cheque Issue Date </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_issuedate | date:'dd/MM/yyyy'}} </td>
            </ng-container>
                
            <ng-container matColumnDef="cd_cashoutdate">
                <th mat-header-cell *matHeaderCellDef> Cheque Cash Date </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_cashoutdate | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="cstatus">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.cstatus}} </td>
            </ng-container>
            <ng-container matColumnDef="cheque_of">
                <th mat-header-cell *matHeaderCellDef> Cheque Of </th>
                <td mat-cell *matCellDef="let element"> {{element.cheque_of}} </td>
            </ng-container>
            <ng-container matColumnDef="cd_cheque_type">
                <th mat-header-cell *matHeaderCellDef> Cheque Type </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_cheque_type}} </td>
            </ng-container>
            <ng-container matColumnDef="cd_issuename">
                <th mat-header-cell *matHeaderCellDef> Issue Name </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_issuename}} </td>
            </ng-container>
            <ng-container matColumnDef="cd_foraccount">
                <th mat-header-cell *matHeaderCellDef> For Account </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_foraccount}} </td>
            </ng-container>

            <ng-container matColumnDef="cashout">
                <th mat-header-cell *matHeaderCellDef> Cashout </th>
                <td mat-cell *matCellDef="let row, let i = index;"> 
                    <button (click)="chequeCashed(row)" mat-flat-button color="warn" [disabled]="state[i+1]">
                        Cash Cheque
                    </button> 
                </td>
            </ng-container>
                            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        </table>

    </div>

    <div style="padding:1%; background-color: white; border-radius: 5px;" [hidden]="goback">

        <table mat-table [dataSource]="dataSource2">

            <ng-container matColumnDef="serial">
                <th mat-header-cell *matHeaderCellDef> S.No. </th>
                <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
            </ng-container>
                    
            <ng-container matColumnDef="cd_custname">
                <th mat-header-cell *matHeaderCellDef> Customer </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_custname}} </td>
            </ng-container>

            <ng-container matColumnDef="cd_amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_amount  | INRCurrency}} </td>
            </ng-container>
                
            <ng-container matColumnDef="cd_issuedate">
                <th mat-header-cell *matHeaderCellDef> Issue Date </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_issuedate | date:'dd/MM/yyyy'}} </td>
            </ng-container>
                
            <ng-container matColumnDef="cd_cashoutdate">
                <th mat-header-cell *matHeaderCellDef> Cash Date </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_cashoutdate | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="cd_witdate">
                <th mat-header-cell *matHeaderCellDef> Withdraw Date </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_witdate | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="cstatus">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.cstatus}} </td>
            </ng-container>

            <ng-container matColumnDef="cheque_of">
                <th mat-header-cell *matHeaderCellDef> Cheque Of </th>
                <td mat-cell *matCellDef="let element"> {{element.cheque_of}} </td>
            </ng-container>

            <ng-container matColumnDef="cd_cheque_type">
                <th mat-header-cell *matHeaderCellDef> Cheque Type </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_cheque_type}} </td>
            </ng-container>

            <ng-container matColumnDef="cd_issuename">
                <th mat-header-cell *matHeaderCellDef> Issue Name </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_issuename}} </td>
            </ng-container>

            <ng-container matColumnDef="cd_foraccount">
                <th mat-header-cell *matHeaderCellDef> For Account </th>
                <td mat-cell *matCellDef="let element"> {{element.cd_foraccount}} </td>
            </ng-container>
                            
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        
        </table>
        <mat-paginator style="margin-top: 1%; border:2px solid;" [pageSize]="5" showFirstLastButtons></mat-paginator>
    </div>

</div>