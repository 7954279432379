<div fxLayout="column" fxLayoutGap="1%" style="background-color: #e5e5e5; padding:1%;">

    <div class="header" fxFlex="10" style="padding:1%" fxLayout="row" fxLayoutAlign="start center">
        <img src="../../assets/revenue.png" style="height:64px; width:64px;">
        <h3 style="margin-left:1%; font-weight: bold; font-size: 300%" [ngStyle.lt-sm]="{'font-size.em':2.5}">Purchase And Expenses</h3>
    </div>

    <div fxFlex="90">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%">
            <app-purchase fxFlex="50"></app-purchase>
            <app-expenses fxFlex="50"></app-expenses>
        </div>
    </div>

</div>