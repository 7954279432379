<div fxLayout="column" fxLayoutGap="1%" style="padding:1%; background-color: #e5e5e5;" >

  <div class="header" fxLayout="row" fxLayoutAlign="start center">
    <img src="../../assets/fd.png" style="height:64px; width:64px;">
    <h3 style="margin-left:1%; font-weight: bold; font-size: 300%">Financial Dashboard</h3>
  </div>
  
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" style="padding:1%">

    <div class="chart_container" fxFlex="50">
      <div class="chart_header">Monthly Sales:</div>
      <canvas baseChart
        [data]="salesBarChartData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="barChartType">
      </canvas>
    </div>
    
    <div class="chart_container" fxFlex="50">
      <div class="chart_header">Monthly Revenue:</div>
      <canvas baseChart
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType">
    </canvas>
    </div>
    
  </div>

  <div style="margin: 2%;" fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" >
    
    <div fxFlex ="33" class="chart_container">
      <div class="chart_header">Office Sales To Revenue Ratio:</div>
      <canvas baseChart
        [data]="offPieChartData"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [type]="pieChartType">
      </canvas>
    </div>
    
    <div fxFlex ="33" class="chart_container">
      <div class="chart_header">New Hira Sales To Revenue Ratio:</div>
      <canvas baseChart
        [data]="nhiuPieChartData"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [type]="pieChartType">
      </canvas>
    </div>

    <div fxFlex ="34" class="chart_container">
      <div class="chart_header">Tata Sales To Revenue Ratio:</div>
      <canvas baseChart
        [data]="tiuPieChartData"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [type]="pieChartType">
      </canvas>
    </div>

  </div>

  <div style="margin: 2%;" fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%">

    <div fxFlex="50" class="chart_container">
      <div class="chart_header">Monthly Avval Sales - New Hira and Tata</div>
      <canvas baseChart
      [data]="AvvalSalesBarChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType">
      </canvas>
    </div>

    <div fxFlex="50" class="chart_container" style="height: 100%;">
      
      <div class="chart_header">Bank Balance</div>
        
      <div>
        <table style="width: 100%;" mat-table [dataSource]="banksDatasource" class="mat-elevation-z8" 
        matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]">

          <ng-container matColumnDef="bank_acholder">
            <th mat-header-cell *matHeaderCellDef> A/C Holder </th>
            <td mat-cell *matCellDef="let element"> {{element.bank_acholder}} </td>
          </ng-container>
          
          <ng-container matColumnDef="bank_name">
              <th mat-header-cell *matHeaderCellDef> Bank </th>
              <td mat-cell *matCellDef="let element">{{element.bank_name}} </td>
          </ng-container>
                  
          <ng-container matColumnDef="bank_acnum">
              <th mat-header-cell *matHeaderCellDef> A/C No. </th>
              <td mat-cell *matCellDef="let element"> {{element.bank_acnum}} </td>
          </ng-container>

          <ng-container matColumnDef="bank_balance">
              <th mat-header-cell *matHeaderCellDef>Balance</th>
              <td mat-cell *matCellDef="let element"> {{element.bank_balance  | INRCurrency}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="bankColumns"></tr>
          <tr class="tablerow"  mat-row *matRowDef="let row; columns: bankColumns;" (click)="openLedger(row)"> </tr>

        </table>  

      </div>

    </div>

  </div>

  <div style="margin: 2%; border: 1px solid black; border-radius: 5px; padding: 1%;" fxLayout="column" fxLayoutGap="1%" >

      <div fxLayout="row" fxLayoutAlign="space-between center" style="background-color: white; padding: 1%;">
        
        <div>
          <h3 [ngStyle.lt-sm]="{'font-size.em':2.5}">Select data between dates:</h3>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="2%" >

          <mat-form-field appearance="outline">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date" (dateInput)="StartDateConversion()">
              <input matEndDate formControlName="end" placeholder="End date" (dateInput)="EndDateConversion()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
          
          <button mat-raised-button class="button" (click)="SendDateRange()" color="primary"> 
            <span fxHide.lt-sm>Show Data</span>
            <mat-icon fxHide.gt-sm>keyboard_return</mat-icon>
          </button>

        </div>
      
      </div>

      <div fxLayout="row" fxLayoutGap="1%" fxLayout.lt-sm="column" [hidden]="showdr"> <!--[hidden]="showdr"-->

        <div class="daterange" fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3%">

          <div style="background-color: #fbceb1; padding:3%; border-radius:5px;" fxLayout="row" fxLayoutAlign="space-between center">
            <img src="assets/pricetag.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">
            <div><h4 style="font-weight: bold;">Sales</h4></div>
          </div>

          <div>
            <table class="drtable">
              
              <tr>
                <td>Office</td>
                <td>{{officeSales | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td>NHIU</td>
                <td>{{hiraSales | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td>TIU</td>
                <td>{{tataSales | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td style="font-weight: bold;">Total Sales</td>
                <td style="font-weight: bold;">{{totalSales | INRCurrency}} Rs.</td>
              </tr>
            </table>
          </div>

        </div>

        <div class="daterange" fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3%">

          <div style="background-color: #98fb98; padding:3%; border-radius:5px;" fxLayout="row" fxLayoutAlign="space-between center">
            <img src="assets/cashregister.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">
            <div><h4 style="font-weight: bold;">Revenue</h4></div>
          </div>

          <div>
            <table class="drtable">
              
              <tr>
                <td>Office</td>
                <td>{{officeIncome | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td>NHIU</td>
                <td>{{hiraIncome | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td>TIU</td>
                <td>{{tataIncome | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td style="font-weight: bold;">Total Revenue</td>
                <td style="font-weight: bold;">{{totalIncome | INRCurrency}} Rs.</td>
              </tr>
            </table>
          </div>

        </div>

        <div class="daterange" fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3%">

          <div style="background-color: #e9967a; padding:3%; border-radius:5px;" fxLayout="row" fxLayoutAlign="space-between center">
            <img src="assets/wallet.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">
            <div><h4 style="font-weight: bold;">Expense</h4></div>
          </div>

          <div>
            <table class="drtable">
              
              <tr>
                <td>Office</td>
                <td>{{officeExpense | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td>NHIU</td>
                <td>{{hiraExpense | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td>TIU</td>
                <td>{{tataExpense | INRCurrency}} Rs.</td>
              </tr>
              <tr>
                <td style="font-weight: bold;">Total Expense</td>
                <td style="font-weight: bold;">{{totalExpense | INRCurrency}} Rs.</td>
              </tr>
            </table>
          </div>

        </div>

        <div class="daterange" fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3%">

          <div style="background-color: #f0e68c; padding:3%; border-radius:5px;" fxLayout="row" fxLayoutAlign="space-between center">
            <img src="assets/piggy.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">
            <div><h4 style="font-weight: bold;">Deposits</h4></div>
          </div>

          <div style="font-weight: bold; align-items: center;">Total Deposits - {{totalDeposit | INRCurrency}} Rs.</div>
        </div>

        <div class="daterange" fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3%">

            <div style="background-color: #b2ffff; padding:3%; border-radius:5px;" fxLayout="row" fxLayoutAlign="space-between center">
              <img src="assets/atm.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">
              <div><h4 style="font-weight: bold;">Withdrawls</h4></div>
            </div>
  
            <div style="font-weight: bold; align-items: center;">Total Withdrawls - {{totalWithdrawl | INRCurrency}} Rs.</div>
        </div>

      </div>

  </div>

</div>