import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';

import { Finservice } from '../services/finservice.service';


@Injectable({
  providedIn: 'root'
})
export class PieResolver implements Resolve<any> {
  
  constructor(private service:Finservice){}
  
  resolve() {return this.service.getOfficePie();}
}
