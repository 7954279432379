
<table mat-table [dataSource]="transactions" >
    
    <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef> S.No. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>

    <ng-container matColumnDef="income_date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.income_date | date:'dd/MM/yyyy'}} </td>
    </ng-container>
        
    <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef> Customer Name </th>
        <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
    </ng-container>
        
    <ng-container matColumnDef="income_amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.income_amount  | INRCurrency}} </td>
    </ng-container>
        
    <ng-container matColumnDef="payment_mode">
        <th mat-header-cell *matHeaderCellDef> Payment Mode </th>
        <td mat-cell *matCellDef="let element"> {{element.payment_mode}} </td>
    </ng-container>

    <ng-container matColumnDef="income_remark">
        <th mat-header-cell *matHeaderCellDef> Remarks </th>
        <td mat-cell *matCellDef="let element"> {{element.income_remark}} </td>
    </ng-container>

    <ng-container matColumnDef="income_ref">
        <th mat-header-cell *matHeaderCellDef> Ref No. </th>
        <td mat-cell *matCellDef="let element"> {{element.income_ref}} </td>
    </ng-container>
           
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
</table>