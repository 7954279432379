import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { map, startWith, switchMap, tap, debounceTime } from 'rxjs/operators';
import { CDTransaction } from  '../Modules/tables';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CreatebankledgerComponent } from '../createbankledger/createbankledger.component';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Injectable({
  providedIn: "root"
})

@Component({
  selector: 'app-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.css'],
  providers: [
    { provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class DepositsComponent implements OnInit {

  depositsForm = new FormGroup({
    bankAccount: new FormControl(''),
    depositDate: new FormControl(new Date()),
    amountDeposited: new FormControl(''),
  });

  constructor(private service: ApiService, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar, public dialog: MatDialog,private router: Router) { }

  bankAccounts: Observable<string>;

  createBL(): void {
    const dialogRef = this.dialog.open( CreatebankledgerComponent , { width: '40vw', height: '72vh', disableClose: true, panelClass: "foo" } ); 
  }

  ngOnInit() {
    this.bankAccounts = this.depositsForm.controls['bankAccount'].valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap(value => this.doBankFilter(value))
    );
  }

  onReset(){
    this.depositsForm.reset();
  }
  
  doCDTransaction(){
    this.service.addCDTransaction(this.depositsForm.value).subscribe((transaction: CDTransaction)=>{
      console.log("Transaction added, ", transaction);
      this.openSuccessSnackBar('Success! Transaction is comeplete.', 'Close')
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');},
    );
    this.onReset();
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
  }
  openSuccessSnackBar(message: string, action: string){
    this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/depwith')}); 
  }

  doBankFilter(value){
    return this.service.getBankData().pipe(map(response =>response.filter(option => {
    return option.bank_name.indexOf(value) === 0;})));
  }

  displayFn(option): string  {
    return option && option.bank_name ? option.bank_name+' | '+option.bank_acholder: '';
  }

}