import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Inventory } from '../Modules/tables'
import { Finservice } from '../services/finservice.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
  providers: [],
})

export class InventoryComponent implements OnInit {

  ngOnInit(): void {}

  constructor(private service: Finservice, private datepipe: DatePipe, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar) {
    this.date = this.datepipe.transform(this.myDate, 'yyyy-MM-dd');
   }

  bricks: string[] = ['Avval', 'Meetha', 'Tukra', 'Godiya', 'Raanth', 'Sema', 'Meetha Tukra'];

  titleH: boolean = true;
  titleT: boolean = true;
  brickNameH: string;
  brickNameT: string;
  activen = 0;
  activet = 0;

  myDate = new Date();
  date: any;

  private nhiupaginator: MatPaginator
  private tiupaginator: MatPaginator

  nhinv:Inventory[];
  tinv:Inventory[];

  nhiudatasource = new MatTableDataSource<Inventory>();
  tiudatasource = new MatTableDataSource<Inventory>();
  updatedInv: Inventory[];
  displayedColumns: string[] = ['serial', 'inv_date', 'inv_sold', 'inv_added', 
  'inv_balance'];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  ngAfterViewInit() {
    this.nhiudatasource.paginator = this.paginator.toArray()[0];
    this.tiudatasource.paginator = this.paginator.toArray()[1];
  }

  setDataSourceAttributes() {
    this.nhiudatasource.paginator = this.nhiupaginator;
  }

  setDataSourceAttributesT() {
    this.tiudatasource.paginator = this.tiupaginator; 
  }

  addedH:number;
  addedT:number;

  UpInv = new FormGroup ({
    itemAdded: new FormControl(''),
    itemType: new FormControl('')
  });

  FetchInventoryHira({value}){
    const itemJSON = {
      itemType: value,
    }
    this.titleH = false;
    this.brickNameH = value;
    this.service.getInventoryNH(itemJSON).subscribe((inv:Inventory[])=>{
      this.nhinv = inv;
      this.nhiudatasource.data = inv;
      console.log(inv);
    })
  }

  FetchInventoryTata({value}) {
    const itemJSON = {
      itemType: value,
    }
    this.titleT = false;
    this.brickNameT = value;
    this.service.getInventoryTata(itemJSON).subscribe((inv:Inventory[])=>{
      this.tinv = inv;
      this.tiudatasource.data = inv;
      console.log(inv);
    })
  }

  UpdateInventoryH(){
    const updateJSON = {
      itemType: this.brickNameH,
      itemQuantity: this.addedH,
      warehouse: 'nhiu'
    }
    console.log(updateJSON);
    this.service.updateInventory(updateJSON).subscribe((invupdate:Inventory[])=>{
      this.updatedInv = invupdate;
      console.log(invupdate);
      this.openSuccessSnackBar('Success! Transaction is comeplete.', 'Close');
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');},
    )
  }

  UpdateInventoryT(){
    const updateJSON = {
      itemType: this.brickNameT,
      itemQuantity: this.addedT,
      warehouse: 'tiu'
    }
    console.log(updateJSON);
    this.service.updateInventory(updateJSON).subscribe((invupdate:Inventory[])=>{
      this.updatedInv = invupdate;
      console.log(invupdate);
      this.openSuccessSnackBar('Success! Transaction is comeplete.', 'Close');
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');},
    )
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
  }
  openSuccessSnackBar(message: string, action: string){
    this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
  }

}