import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-correct-date',
  template: `
  <mat-form-field appearance="outline" style="width:100%">
  <mat-label>{{title}}</mat-label>
  <input matInput [matDatepicker]="picker" [value]="dateValue" (dateInput)="addEvent('input', $event)" >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CorrectDateComponent),
      multi: true
    },
  ]
})
export class CorrectDateComponent implements ControlValueAccessor {

  propagateChange = (_: any) => { };

  @Input() title: string;

  @Input() _dateValue;

  get dateValue() {
    return moment(this._dateValue, 'YYYY-MM-DD');
  }

  set dateValue(val) {
    this._dateValue = moment(val).format('YYYY-MM-DD');
    this.propagateChange(this._dateValue);
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dateValue = moment(event.value, 'YYYY-MM-DD');
  }


  registerOnChange(fn:any): void {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  writeValue(value: any) {
      this.dateValue = moment(value, 'YYYY-MM-DD');
  }

}
