import { Component, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import { CWTransaction } from '../Modules/tables';
import { DaybookService } from '../services/daybook.service';

@Component({
  selector: 'app-withdrawl-income',
  templateUrl: './withdrawl-income.component.html',
  styleUrls: ['./withdrawl-income.component.css']
})
export class WithdrawlIncomeComponent implements OnInit {

  constructor(private service: DaybookService, private datepipe: DatePipe) { }  

ngOnInit() {
  this.service.events$.forEach(event => this.catchEvent(event));

}

date: string;
childDate: Date;
filteredParentDate: Date;
transactions: CWTransaction[];

displayedColumns: string[] = ['serial', 'wit_id', 'wit_date', 'wit_amount', 'bank_account'];

  catchEvent(event){
    this.filterTransactionByDate(event);
  }
  

  filterTransactionByDate(event) {
    this.service.filteredDate.subscribe(childDate => this.childDate = childDate);
    this.filteredParentDate = this.childDate;
    this.date = this.datepipe.transform(this.filteredParentDate, 'yyyy-MM-dd');
    this.service.readWitIncome(this.date).subscribe((transactions: CWTransaction[])=>{
      this.transactions = transactions;
      console.log(this.transactions);})
  }

}
