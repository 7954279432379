import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Creditor, CustDB } from  '../Modules/tables';
import {MatSnackBar} from '@angular/material/snack-bar'

@Component({
  selector: 'app-create-cred',
  templateUrl: './create-cred.component.html',
  styleUrls: ['./create-cred.component.css']
})
export class CreateCredComponent implements OnInit {

  constructor(private service: ApiService, private snackbar: MatSnackBar) { }  

  ngOnInit(): void {
  }

  customers:  Creditor[];
  customerDB: CustDB[];
  public customer_us: any;

  cred_of: string[] = ['Office','NHIU','TIU'];
  typeof: string[] = ['Creditor', 'Saapat']

  newCreditor = new FormGroup({
      name: new FormControl('', Validators.required),
      cred_of: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      opening: new FormControl('', Validators.required),
      type : new FormControl('', Validators.required),
  });

  addNewCreditor(){
    this.service.createCreditor(this.newCreditor.value).subscribe((creditor: Creditor)=>{
      console.log(this.newCreditor.value);
      console.log("Creditor added, ", creditor);
      this.newCreditor.reset();
    })
  };

  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }
  onReset(){
    this.newCreditor.reset();
  }

}
