import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Finservice } from '../services/finservice.service';
import {BankACShort,daterange} from '../Modules/tables';
import {MatTableDataSource} from '@angular/material/table';
import {FormGroup, FormControl} from '@angular/forms';
import { formatDate } from '@angular/common';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { BankledgerComponent } from '../bankledger/bankledger.component';
import { LedgerService } from '../services/ledger.service';

import { ChartType, ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { ActivatedRoute } from '@angular/router';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-fin-report',
  templateUrl: './fin-report.component.html',
  styleUrls: ['./fin-report.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class FinReportComponent implements OnInit {

  @ViewChild( BaseChartDirective,  {static: false} ) chart: BaseChartDirective | undefined;
  
  constructor(private Fin:Finservice, private LS:LedgerService, public dialog: MatDialog, private http:HttpClient, private actRoute: ActivatedRoute) {}

  bankName:string;
  acHolderName:string;

  ngOnInit() {

    this.Fin.getBankBalance().subscribe((bankdata: BankACShort[])=>{
      this.banksDatasource.data = bankdata;
    });
    
    this.actRoute.data.subscribe(results => {                               //resolver service
      this.monthly_income = results.rd;

      for (let i = 0; i < 12; i++) {
        this.officeMonthlyIncome[i] = this.monthly_income[i];
      }
      for (let i = 12; i < 24; i++) {
        this.hiraMonthlyIncome[i-12] = this.monthly_income[i];
      }
      for (let i = 24; i < 36; i++) {
        this.tataMonthlyIncome[i-24] = this.monthly_income[i];
      }
    });

    this.actRoute.data.subscribe( results => {                        //sale resolver
      this.monthly_sales = results.sale;

      for (let i = 0; i < 12; i++) {
        this.officeMonthlySales[i] = this.monthly_sales[i];
      }

      for (let i = 12; i < 24; i++) {
        this.hiraMonthlySales[i-12] = this.monthly_sales[i];
      }
      for (let i = 24; i < 36; i++) {
        this.tataMonthlySales[i-24] = this.monthly_sales[i];
      }
    });

    this.actRoute.data.subscribe( results => {                    //avval resolver
      this.monthly_avval_sales = results.avval;

      for (let i = 0; i < 12; i++) {
        this.hiraMonthlyAvvalSales[i] = this.monthly_avval_sales[i];
      }

      for (let i = 12; i < 24; i++) {
        this.tataMonthlyAvvalSales[i-12] = this.monthly_avval_sales[i];
      }
    });

    this.actRoute.data.subscribe( results => {                          //pie resolver
//      this.monthly_income = results.rd;                   //resolver service
      this.PieData = results.pie;
    });

    for( let i=0; i<2; i++ ){
      this.opd.push(this.PieData[i]);
    }
    for( let i=2; i<4; i++ ){
      this.npd.push(this.PieData[i]);
    }
    for( let i=4; i<6; i++ ){
      this.tpd.push(this.PieData[i]);
    }

    this.Fin.getReceivables().subscribe(rec=>{
      this.receivables = rec;
      this.offReceivables = this.receivables[0];
      this.nhiuReceivables = this.receivables[1];
      this.tiuReceivables = this.receivables[2];
    })

  }

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });

  dr: daterange;
  sc: any;
  ec: any;
  showdr:boolean = true;
  acnum:number;

  viewBankLedger(header): void {
    const dialogRef = this.dialog.open( BankledgerComponent , { width: '80vw', height: '80vh', disableClose: true, panelClass: "foo",
                                                                data: {bankName:header.bn, achName: header.acname}
                                                              }); 
  }

  openLedger(row) {
    this.acnum = row.bank_acnum;
    this.bankName = row.bank_name;
    this.acHolderName = row.bank_acholder;
    const header = {
      bn: this.bankName,
      acname: this.acHolderName
    }
    this.Fin.getBankACNum(this.acnum);
    this.viewBankLedger(header);
  }

  StartDateConversion(){
    const startconverted = formatDate(this.range.value.start, 'YYYY-MM-dd', 'en-GB', '+0530');
    this.sc =startconverted;
  }

  EndDateConversion(){
    const endconverted = formatDate(this.range.value.end, 'YYYY-MM-dd', 'en-GB', '+0530');
    this.ec =endconverted;
  }

  SendDateRange() {
    this.dr ={
      start:  this.sc,
      end:    this.ec
    }
    this.showdr = false;
    this.Expenses(this.dr);
    this.Sales(this.dr);
    this.Income(this.dr);
    this.Withdrawls(this.dr);
    this.Deposits(this.dr);
  }

  Expenses(dr){
    this.Fin.getTotalExpense(dr).subscribe((totalExpenses:any)=>{
      this.ExpenseResults = totalExpenses;
      this.officeExpense  = this.ExpenseResults[0];
      this.hiraExpense  = this.ExpenseResults[1];
      this.tataExpense  = this.ExpenseResults[2];
      this.totalExpense  = this.ExpenseResults[3];
    })
  }
  Sales(dr){
    this.Fin.getTotalSales(dr).subscribe((totalSales:any)=>{
      this.SalesResults = totalSales;
      this.officeSales  = this.SalesResults[0];
      this.hiraSales  = this.SalesResults[1];
      this.tataSales  = this.SalesResults[2];
      this.totalSales  = this.SalesResults[3];
    })
  }
  Income(dr){
    this.Fin.getTotalIncome(dr).subscribe((totalIncome:any)=>{
      this.IncomeResults = totalIncome;
      this.officeIncome  = this.IncomeResults[0];
      this.hiraIncome    = this.IncomeResults[1];
      this.tataIncome    = this.IncomeResults[2];
      this.totalIncome   = this.IncomeResults[3];
    })
  }
  Withdrawls(dr){
    this.Fin.getTotalDeposits(dr).subscribe((totaldeposits:number)=>{
      this.totalDeposit = totaldeposits;})
  }

  Deposits(dr){
    this.Fin.getTotalWithdrawl(dr).subscribe((totalwithdrawl:number)=>{
      this.totalWithdrawl = totalwithdrawl;})
  }

  IncomeResults: any;
  ExpenseResults: any;
  DepositResults: any;
  WithdrawlResults: any;
  SalesResults: any;

  totalIncome: number;
  totalExpense: number;
  totalDeposit: number;
  totalWithdrawl: number;
  totalSales: number;

  officeIncome: number;
  officeExpense: number;
  officeSales: number;

  tataIncome: number;
  tataExpense: number;
  tataSales: number;

  hiraIncome: number;
  hiraExpense: number;
  hiraSales: number;

  bankColumns: string[] = ['bank_acholder', 'bank_name', 'bank_acnum', 'bank_balance'];
  banksDatasource = new MatTableDataSource<BankACShort>();
  chartConfig: Object;
  dataSource: Object;
  title: string;
  public monthly_income: any;
  public monthly_sales: any;
  public monthly_avval_sales: any;
  public chartdata: number[] = [];
  public officeMonthlyIncome: number[] = [];
  public hiraMonthlyIncome: number[] = [];
  public tataMonthlyIncome: number[] = [];
  public officeMonthlySales: number[] = [];
  public hiraMonthlySales: number[] = [];
  public tataMonthlySales: number[] = [];
  public hiraMonthlyAvvalSales: number[] = [];
  public tataMonthlyAvvalSales: number[] = [];
  public PieData : number[] = [];
  public opd: number[] = [];
  public npd: number[] = [];
  public tpd: number[] = [];
  receivables: any[];
  offReceivables: number;
  nhiuReceivables: number;
  tiuReceivables: number;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: { x: {grid:{display:false}}, y: {grid:{display:false}} },
    plugins: {
      legend: {display: true, position: 'bottom',},
      datalabels: {display: false}
    }
  };

  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];

  public barChartData: ChartData<'bar'> = {
    labels: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec'],
    datasets: [ 
      { data: this.officeMonthlyIncome, label: 'OFF', backgroundColor:'#950E10'},
      { data: this.hiraMonthlyIncome, label: 'NHIU', backgroundColor:'#072422'},
      { data: this.tataMonthlyIncome, label: 'TIU', backgroundColor:'#1C5373'},
    ] 
  };

  public salesBarChartData: ChartData<'bar'> = {
    labels: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec'],
    datasets: [ 
      { data: this.officeMonthlySales, label: 'OFF', backgroundColor:'#950E10'},
      { data: this.hiraMonthlySales, label: 'NHIU', backgroundColor:'#072422'},
      { data: this.tataMonthlySales, label: 'TIU', backgroundColor:'#1C5373'},
    ] 
  };

  public AvvalSalesBarChartData: ChartData<'bar'> = {
    labels: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec'],
    datasets: [ 
      { data: this.hiraMonthlyAvvalSales, label: 'NHIU', backgroundColor:'#072422'},
      { data: this.tataMonthlyAvvalSales, label: 'TIU', backgroundColor:'#1C5373'},
    ] 
  };

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        }
      },
      datalabels: {
        color: '#000000',
        formatter: (value, ctx) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    }
  };

  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [ DataLabelsPlugin ];

  public offPieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [['Sales'],['Revenue']],
    datasets: [ { 
      data: this.opd,
      backgroundColor: [
        '#fd5c63',
        '#006241'],
      hoverBackgroundColor:[
        '#F88379',
        '#90EE90'
      ],
      hoverBorderColor:[
        '#F88379',
        '#90EE90'
      ],
    } ]
  };

  public nhiuPieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [['Sales'],['Revenue']],
    datasets: [ { 
      data: this.npd,
      backgroundColor: [
        '#fd5c63',
        '#006241'],
      hoverBackgroundColor:[
        '#F88379',
        '#90EE90'
      ],
      hoverBorderColor:[
        '#F88379',
        '#90EE90'
      ],
    } ]
  };

  public tiuPieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [['Sales'],['Revenue']],
    datasets: [ { 
      data: this.tpd,
      backgroundColor: [
        '#fd5c63',
        '#006241'],
      hoverBackgroundColor:[
        '#F88379',
        '#90EE90'
      ],
      hoverBorderColor:[
        '#F88379',
        '#90EE90'
      ],
    } ]
  };

  public pieChartLegend = true;
  
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

}
