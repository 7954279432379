import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { CustomerLedgersComponent } from './customer-ledgers/customer-ledgers.component';
import { IncomeAndSalesComponent} from './income-and-sales/income-and-sales.component';
import { DepositsAndWithdrawlComponent } from './deposits-and-withdrawl/deposits-and-withdrawl.component';
import { PurchaseAndExpensesComponent } from './purchase-and-expenses/purchase-and-expenses.component';
import { DaybookComponent} from './daybook/daybook.component';
import { CreditorsLedgerComponent} from './creditors-ledger/creditors-ledger.component';
import { FinReportComponent} from './fin-report/fin-report.component';
import { ChequesDueComponent} from './cheques-due/cheques-due.component';
import { InventoryComponent} from './inventory/inventory.component';
import { AuthguardGuard } from './LoginAndRegisterationService/authguard.guard';
import { CustomersComponent } from './customers/customers.component';
import { ResolverService } from './resolvers/reseolver.service';
import { PieResolver } from './resolvers/pie.resolver';
import { MonthlySalesResolver } from './resolvers/monthly-sales.resolver'; 
import { AvvalSalesResolver } from './resolvers/avval-sales.resolver';
import { SaapatComponent } from './saapat/saapat.component';

const accountModule = () => import('./LoginAndRegisterationService/account.module').then(x => x.AccountModule);

const routes: Routes = [

  { path: 'account', loadChildren: accountModule },

  { path: '', component: MainComponent,  canActivate: [AuthguardGuard],
    children: [
      {path: 'salesandincome', component:IncomeAndSalesComponent},
      {path: 'purexp', component:PurchaseAndExpensesComponent},
      {path: 'depwith', component:DepositsAndWithdrawlComponent},
      {path: 'customers', component:CustomersComponent},
      {path: 'daybook', component:DaybookComponent},
      {path: 'customerledger', component:CustomerLedgersComponent},
      {path: 'creditorsledger', component:CreditorsLedgerComponent},
      
      {path: 'finance', component: FinReportComponent, resolve: { rd : ResolverService, pie : PieResolver, sale : MonthlySalesResolver, 
                                                                  avval: AvvalSalesResolver},  
      },
      {path: 'chequesdue', component:ChequesDueComponent},
      {path: 'inventory', component:InventoryComponent},
      {path: 'saapat', component:SaapatComponent},
    ]
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }