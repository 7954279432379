import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Customer, CustDB, BankAC } from  '../Modules/tables';
import { Pipe, PipeTransform } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root"
})

@Component({
  selector: 'app-createbankledger',
  templateUrl: './createbankledger.component.html',
  styleUrls: ['./createbankledger.component.css']
})
export class CreatebankledgerComponent implements OnInit {

  constructor(private service: ApiService, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar) { } 

  ngOnInit(): void {
  }

customers:  Customer[];
customerDB: CustDB[];
public customer_us: any;
items: string[] = ['Savings','Current'];

newBank = new FormGroup({
  bank_name: new FormControl('', Validators.required),
  account_of: new FormControl('', Validators.required),
  account_number: new FormControl('', Validators.required),
  bank_balance: new FormControl('', Validators.required),
  account_type: new FormControl('', Validators.required),
});


  addNewBank(){
    this.service.createBankAccount(this.newBank.value).subscribe((bankac: BankAC)=>{
      this.newBank.reset();
      this.openSuccessSnackBar('Bank account created', 'Close');
    },    
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');}
    );
  };

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    this.errorsnackBar.open(message, action,
      {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });

  }

  openSuccessSnackBar(message: string, action: string){
    this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
      
    });
  }

  onReset(){
    this.newBank.reset();
  }

}

@Pipe({ name: 'removeUnderscore' })
  
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return value.replace(/ /g, "_");
  }
}
