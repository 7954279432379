<mat-card class="expense-card">
    
    <mat-card-title style="color:white; background-color: #0B0C12; padding:2%;"> Expenses</mat-card-title>
       
    <mat-card-content>   
            
        <form [formGroup]="expensesForm" (ngSubmit)="doETransaction()">

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1%" style="padding:2%;">

                <div fxLayout="row" fxLayoutGap="1%">
                    <app-correct-date fxFlex="92%" [title]="'Select Date'" formControlName="expenseDate"></app-correct-date>
                    <button type="button" class="custbutton" fxFlex="8%" (click)="createNewCreditor()" fxFlex="8%">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>

                <div>

                    <div fxLayout="row" fxLayoutGap="1%">
                        
                        <mat-form-field appearance="outline" fxFlex="20">
                            <mat-label>Expense Of</mat-label>
                            <mat-select formControlName="expenseOf" (selectionChange)="ExpType($event)">
                                <mat-option *ngFor="let choice of expense_of" [value]="choice">
                                    {{choice}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="30">
                            <mat-label>Expense Type</mat-label>
                            <mat-select formControlName="expenseType" (selectionChange)="useBene($event)">
                                <mat-option *ngFor="let item of expense_type" [value]="item">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="beneficiary" appearance="outline" fxFlex="50" [hidden]="bene">
                            <mat-label>Beneficiary Name</mat-label>
                            <input type="text" matInput [matAutocomplete]="auto" formControlName="beneficiaryName">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.cred_name">
                                        {{option.cred_name}}
                                    </mat-option>
                                </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="beneficiary" appearance="outline" fxFlex="50" [hidden]="saapat">
                            <mat-label>Beneficiary Name (Saapat)</mat-label>
                            <input type="text" matInput [matAutocomplete]="sp" formControlName="saapatName">
                                <mat-autocomplete #sp="matAutocomplete">
                                    <mat-option *ngFor="let choice of saapatOptions | async" [value]="choice.sp_name">
                                        {{choice.sp_name}}
                                    </mat-option>
                                </mat-autocomplete>
                        </mat-form-field>

                    </div>

                </div>

                <mat-form-field class="amtSpent" appearance="outline">
                    <mat-label>Amount Spent</mat-label>
                    <input type="text" matInput formControlName="amountSpent">
                </mat-form-field>

                <div>
                    
                    <div [ngSwitch]="otherIncomes" fxLayout="row" fxLayoutGap="1%"> 
                        
                        <mat-form-field class="MOP" appearance="outline" fxFlex="30">
                            <mat-label>Payment Mode:</mat-label>
                            <mat-select formControlName="paymentMode" (selectionChange)="useChequeDate($event)">
                                <mat-option *ngFor="let item of items" [value]="item" >
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> 
                    
                        <mat-form-field *ngSwitchCase="'Cheque'" class="customer" appearance="outline" fxFlex="70">
                            <mat-label>Select Bank AC</mat-label>
                            <input type="text" matInput [matAutocomplete]="bank" formControlName="bankAccount">
                                <mat-autocomplete #bank="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of bankAccounts | async" [value]="option">
                                        {{option.bank_name }} || {{ option.bank_acholder}}
                                    </mat-option>
                                </mat-autocomplete>
                        </mat-form-field> 
                    
                    </div>

                </div>

                <mat-form-field class="remarks" appearance="outline">
                    <mat-label>Remarks/Particulars</mat-label>
                    <input type="text" matInput formControlName="expenseRemark">
                </mat-form-field>

                <div>
                    
                    <div fxLayout="row" fxLayoutAlign="space-around center">
                        
                        <button type="submit" mat-raised-button color="warn" class="submitbutton" [disabled]="!expensesForm.valid"> Submit </button>
                        <button type="button" mat-raised-button color="warn" class="submitbutton" (click)="onReset()"> Reset Fields </button>

                    </div>

                </div>

            </div>

        </form>

    </mat-card-content>

</mat-card>
