<div fxLayout="column" [ngSwitch]="isVisible" style="padding:1%; background-color: #e5e5e5">

    <div class="header" fxFlex="10" style="padding:1%" fxLayout="row" fxLayoutAlign="start center">
        <img src="../../assets/small.png" style="height:64px; width:64px;">
        <h3 style="margin-left:1%; font-weight: bold; font-size: 300%">Creditors' Ledger</h3>
    </div>


    <div *ngSwitchCase="true" fxFlex="90" class="INDEX" fxLayout="column">

        <div fxFlex fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="start stretch" class="indexheader">

            <div fxLayout="column" fxFlex="10" fxFlex.lt-sm="15" style="color:white; background-color:#0B0C12; border-top-left-radius: 5px; 
            border-bottom-left-radius: 5px;" fxLayoutAlign="center center">
                <h2 class="indexh3" [ngStyle.lt-sm]="{'font-size.px':20}">Index-</h2>
            </div>

            <div class="REST" fxFlex="90" fxFlex.lt-sm="85" fxLayoutAlign="space-between center" fxLayoutGap="1%" style="padding: .5% .5%;">

                <div fxLayout="row" fxLayoutGap="1%">
                    <div>
                        <mat-form-field appearance="outline" [ngStyle.lt-sm]="{'width.px':150}">
                            <mat-label [ngStyle.lt-sm]="{'font-size.px':12}">Select Letter</mat-label>
                            <mat-select (selectionChange)="selectedLetter($event)">
                                <mat-option *ngFor="let letter of alphabet" [value]="letter">
                                    {{letter}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline"  style="margin-right: 2%;" [ngStyle.lt-sm]="{'width.px':150}">
                            <mat-label [ngStyle.lt-sm]="{'font-size.px':12}">Filter Names:</mat-label>
                            <input matInput (keyup)="applyFilter($event)">
                        </mat-form-field>
                    </div>

                </div>

            </div>

        </div>
    
        <div fxFlex style="background-color: white; border-radius: 5px; padding: 1%;">
                
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="indextable" 
                matTableExporter #defexporter="matTableExporter" [hiddenColumns]="[0]" style="border-radius: 5px;">
                    
                <ng-container matColumnDef="serialnum">
                    <th mat-header-cell *matHeaderCellDef> S.No. </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                </ng-container>
                        
                <ng-container matColumnDef="cred_name">
                    <th mat-header-cell *matHeaderCellDef> Creditor Name </th>
                    <td mat-cell *matCellDef="let element">{{element.cred_name}} </td>
                </ng-container>
                
                <ng-container matColumnDef="cred_num">
                    <th mat-header-cell *matHeaderCellDef> Creditor Contact </th>
                    <td mat-cell *matCellDef="let element"> {{element.cred_num}} </td>
                </ng-container>
                
                <ng-container matColumnDef="cred_of">
                    <th mat-header-cell *matHeaderCellDef> Creditor Of </th>
                    <td mat-cell *matCellDef="let element"> {{element.cred_of}} </td>
                </ng-container>

                <ng-container matColumnDef="current_balance">
                    <th mat-header-cell *matHeaderCellDef> Balance Amount </th>
                    <td mat-cell *matCellDef="let element"> {{element.cred_balance  | CreditDebit}} </td>
                </ng-container>

                        
                <tr mat-header-row *matHeaderRowDef="creditorList"></tr>
                <tr class="tablerow"  mat-row *matRowDef="let row; columns: creditorList;" (click)="openLedger(row)"> </tr>

            </table>

        </div>

    </div>

    <div fxFlex="90" *ngSwitchCase="false" [@slideLeftToRight] class="ledger" fxLayout="column">

        <div fxFlex fxLayout="row" fxLayoutAlign="start stretch" class="indexheader" fxLayoutGap="1%">

            <div fxLayout="column" fxFlex="15" fxFlex.lt-sm="20" style="color:white; background-color:#0B0C12; border-top-left-radius: 5px; 
            border-bottom-left-radius: 5px;" fxLayoutAlign="center center">
                <h2 class="indexh3" [ngStyle.lt-sm]="{'font-size.px':20}">Accounts</h2>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="85" fxFlex.lt-sm="80" fxLayoutGap="1%" style="padding:1% 0;">

                <div class="TITLES" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%" style="width: 100%;">

                    <div style="background-color: #0B0C12; border-radius: 50%; color: white; font-weight: bold; font-size: medium; padding: 1%;">{{cred_of_circle}}</div>
                    
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3px" style="border: 1px solid #0B0C12; padding: 1%;" [ngStyle.lt-sm]="{'font-size.px':10}">
                        
                        <mat-icon>face</mat-icon>
                        <span fxShow>Creditor Name: </span>
                        <div style="font-weight: bold;"> {{creditorName}}</div>
                
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3px" style="border: 1px solid #0B0C12; padding: 1%;" [ngStyle.lt-sm]="{'font-size.px':10}">
                        
                        <mat-icon>paid</mat-icon>
                        <span fxShow>Current Balance: </span>
                        <div style="font-weight: bold;"> {{ledgerBalance | CreditDebit}}</div>

                    </div>

                </div>

                <div class="BUTTONS" fxFlexOffset.xs="0" fxLayout="row" fxLayoutGap="3%" fxLayoutAlign="center center">

                    <button class="saveledger" mat-icon-button color="primary" ngClass.xs="button_small" style="margin-right: 2%;" matTooltip="Dowload Ledger in XL">
                        <mat-icon aria-hidden="false" aria-label="Back icon">save_alt</mat-icon>
                    </button>

                    <button ngClass.xs="button_small" class="saveledger" mat-icon-button color="warn" (click)="goBack()" style="margin-right: 2%;" matTooltip="Back To Index">
                        <mat-icon aria-hidden="false" aria-label="Back icon">keyboard_backspace</mat-icon>
                        
                    </button>

                    <button mat-icon-button color="accent" ngClass.xs="button_small" class="saveledger" (click)="openDialog()" matTooltip="Edit/Update Ledger">
                        <mat-icon aria-hidden="false" aria-label="Back icon">settings</mat-icon>
                    </button>

                </div>

            </div>

        </div>

        <div style="background-color: white; border-radius: 5px; padding: 1%;">
                <table style="width: 100%;" mat-table [dataSource]="CredLedgerDataSource" class="mat-elevation-z8" 
                    matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]">
                    
                <ng-container matColumnDef="serial">
                    <th mat-header-cell *matHeaderCellDef> S.No. </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                </ng-container>
                        
                <ng-container matColumnDef="crl_date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element">{{element.crl_date}} </td>
                </ng-container>
                        
                <ng-container matColumnDef="crl_ref">
                    <th mat-header-cell *matHeaderCellDef> Ref. No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.crl_ref}} </td>
                </ng-container>
                        
                <ng-container matColumnDef="crl_part">
                    <th mat-header-cell *matHeaderCellDef> Particulars </th>
                    <td mat-cell *matCellDef="let element"> {{element.crl_part}} </td>
                </ng-container>

                <ng-container matColumnDef="crl_debit">
                    <th mat-header-cell *matHeaderCellDef> Debit Amount</th>
                    <td mat-cell *matCellDef="let element"> {{element.crl_debit  | INRCurrency}} </td>
                </ng-container>

                <ng-container matColumnDef="crl_credit">
                    <th mat-header-cell *matHeaderCellDef> Credit Amount</th>
                    <td mat-cell *matCellDef="let element"> {{element.crl_credit  | INRCurrency}} </td>
                </ng-container>

                    
                <ng-container matColumnDef="crl_balance">
                    <th mat-header-cell *matHeaderCellDef> Balance </th>
                    <td mat-cell *matCellDef="let element"> {{element.crl_balance  | CreditDebit}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="ledgerColumns"></tr>
                <tr class="tablerow"  mat-row *matRowDef="let row; columns: ledgerColumns;"> </tr>

            </table>

            <mat-paginator style="margin-top: 1%; border:2px solid;" [pageSize]="5" showFirstLastButtons></mat-paginator>
        </div>

    </div>

</div>