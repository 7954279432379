<div><h1 mat-dialog-title>Cheque Cash Confirmation</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to cash cheque?</p>
  </div>
  <div class="DATE">
    <app-correct-date [title]="'Select Date'" [(ngModel)]="selectDate"></app-correct-date>
  </div>
  <div mat-dialog-actions>
      <button mat-button mat-dialog-close (click)="cashCheque()">Yes</button>
      <button mat-button mat-dialog-close>Cancel</button>
  </div>
</div>
