import { Component, OnInit } from '@angular/core';
import {Customer, Saapat, SaapatLedger} from '../Modules/tables'
import { trigger, style, transition, animate} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {formatDate} from '@angular/common';
import { SaapatCorrectionComponent } from '../saapat-correction/saapat-correction.component';
import {MatDialog} from '@angular/material/dialog';
import { LedgerService } from '../services/ledger.service';

@Component({
  selector: 'app-saapat',
  templateUrl: './saapat.component.html',
  styleUrls: ['./saapat.component.css'],
  animations: [
    trigger('slideLeftToRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms 300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideRightToLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms 300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})

export class SaapatComponent implements OnInit {

  constructor(private service: LedgerService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.service.getSaapat().subscribe((saapat:Saapat[])=> {
      this.saapat = saapat;
      this.dataSource.data = this.saapat;
    })
  }

  SaapatLedgerDataSource = new MatTableDataSource<SaapatLedger>();

  sp_of_circle: string;
  deflistfilename: string;
  today =  formatDate(new Date(), 'dd-MM-YYYY', 'en', '+0530');
  saapat_of: string;
  isVisible = true;

  saapatName: string;
  
  ledgerBalance: number;
  showDef:boolean = false;
  printDefButton:boolean = true;
 
  element: HTMLElement;
  custList: Customer[];
  emptyDataSource: Saapat[];
  spLedger: SaapatLedger[];

  ledgerColumns: string[] = ['serial', 'spl_date', 'spl_ref', 'spl_part', 'spl_debit', 'spl_credit', 'spl_balance'];
  saapatList: string[] = ['serialnum', 'sp_name', 'sp_of', 'current_balance'];

  saapat: Saapat[];
  dataSource = new MatTableDataSource<Saapat>();
  
  showIndex(){
    this.showDef = !this.showDef;
    this.printDefButton = !this.printDefButton;
    this.dataSource.data = this.emptyDataSource;
    console.log(this.emptyDataSource);
  }

  goBack(){
    this.isVisible = !this.isVisible;
  }

  public beginsWith = "";
  public spDBName;
  public defaulterList;
  public ledgerFileName;
  
  spname: string;
  borrower_of:string;

  openLedger(row){

    this.isVisible = !this.isVisible;
    this.saapatName = row.sp_name;

    this.borrower_of = row.sp_of;

    if(this.borrower_of == 'Office'){
      this.sp_of_circle = 'OFF';
    }
    else{
      this.sp_of_circle = this.saapat_of;
    }
    
    this.spname = row.sp_name;
    this.spDBName = row.sp_name.replace(/ /g, "_").toLowerCase();
    const ledgerJSON = {
      dbname: this.spDBName,
    };

    this.service.readSaapatLedger(ledgerJSON).subscribe((spLedger: SaapatLedger[])=>{
    this.spLedger = spLedger;
    this.SaapatLedgerDataSource.data = spLedger;
    })
    
    this.service.spLedgerBalance(ledgerJSON).subscribe((ledgerBalance:number)=>{
      this.ledgerBalance = ledgerBalance;
    })
  }
  
  openDialog(): void{
    const dialogRef = this.dialog.open(SaapatCorrectionComponent, {
      width: '500px',
      data: {name: this.spname},
    });
  }

}