<table mat-table [dataSource]="transactions" >
    
    <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef> S.No. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>

    <ng-container matColumnDef="dep_date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.dep_date | date:'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dep_amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.dep_amount  | INRCurrency}} </td>
    </ng-container>
    
    <ng-container matColumnDef="bank_account">
        <th mat-header-cell *matHeaderCellDef> Bank Account </th>
        <td mat-cell *matCellDef="let element"> {{element.bank_account}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
</table>