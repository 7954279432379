<div class="expdep" fxLayout="column" fxLayoutGap="1%" style="background-color: #e5e5e5; padding:1%;">

    <div class="header" fxFlex="10" style="padding:1%" fxLayout="row" fxLayoutAlign="start center">
        <img src="../../assets/piggybank.png" style="height:64px; width:64px;">
        <h3 style="margin-left:1%; font-weight: bold; font-size: 300%" [ngStyle.lt-sm]="{'font-size.em':2.5}">Deposit And Withdrawl</h3>
    </div>

    <div fxFlex="90">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%">
            <app-deposits fxFlex="50"></app-deposits>
            <app-withdrawl fxFlex="50"></app-withdrawl>
        </div>
    </div>