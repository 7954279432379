import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AccountService } from './account.service';

@Injectable({providedIn: 'root'})

export class AuthguardGuard implements CanActivate {

    constructor(private dataService: ApiService, private router: Router, private accountService:AccountService ) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        if (user) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}



/*     
    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const routeurl: string = state.url;
        return this.isLogin(routeurl);
    }

    isLogin(routeurl: string) {
        if (this.dataService.isLoggedIn()) {
        return true;
        }

        this.dataService.redirectUrl = routeurl;
        this.router.navigate(['/login'], {queryParams: { returnUrl: routeurl }} );
    }
*/