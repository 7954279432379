<div class="body" style="height: 430px; overflow-y: scroll; overflow: overlay;">

    <table mat-table [dataSource]="transactions" >
    
    <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef> S.No. </th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
    </ng-container>

    <ng-container matColumnDef="exp_date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.exp_date | date:'dd/MM/yyyy'}} </td>
    </ng-container>
        
    <ng-container matColumnDef="exp_type">
        <th mat-header-cell *matHeaderCellDef> Expense Type </th>
        <td mat-cell *matCellDef="let element"> {{element.exp_type}} </td>
    </ng-container>

    <ng-container matColumnDef="beneficiary_name">
        <th mat-header-cell *matHeaderCellDef> Beneficiary Name </th>
        <td mat-cell *matCellDef="let element"> {{element.beneficiary_name}} </td>
    </ng-container>
        
    <ng-container matColumnDef="exp_amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.exp_amount  | INRCurrency}} </td>
    </ng-container>
        
    <ng-container matColumnDef="payment_mode">
        <th mat-header-cell *matHeaderCellDef> Payment Mode </th>
        <td mat-cell *matCellDef="let element"> {{element.payment_mode}} </td>
    </ng-container>

    <ng-container matColumnDef="bank_account">
        <th mat-header-cell *matHeaderCellDef> Bank Account </th>
        <td mat-cell *matCellDef="let element"> {{element.bank_account}} </td>
    </ng-container>

    <ng-container matColumnDef="exp_remark">
        <th mat-header-cell *matHeaderCellDef> Remarks </th>
        <td mat-cell *matCellDef="let element"> {{element.exp_remark}} </td>
    </ng-container>
           
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
</table>
</div>