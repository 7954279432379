import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS} from 'date.adapter';
import { DatePipe } from '@angular/common';
import {ETransaction, filterdate} from '../Modules/tables'
import {DaybookService} from '../services/daybook.service';
import { company_table_name } from '../Modules/tables';

@Component({
  selector: 'app-expensereport',
  templateUrl: './expensereport.component.html',
  styleUrls: ['./expensereport.component.css'],
  providers: [DatePipe,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})
export class ExpensereportComponent implements OnInit {

  constructor(private service: DaybookService, private datepipe: DatePipe) { }  

  ngOnInit() {
    this.service.events$.forEach(event => this.catchEvent(event));
    this.service.changeTransactionOf.forEach(newSaleOf => this.expenseof = newSaleOf);
    this.service.source_change_event.forEach(change_event => this.changeSource(change_event));
  }

  expenseof:string = "Office";
  tabledata: company_table_name;
  filterDate: filterdate[];
  filteredParentDate: Date;
  nextevent: Event;
  filteredData : ETransaction[];
  transactions: ETransaction[];
  
  displayedColumns: string[] = ['serial', 'exp_date', 'exp_type', 'beneficiary_name', 'exp_amount', 
  'payment_mode', 'bank_account', 'exp_remark'];
  
  childDate: Date;
  date: string;
  
  catchEvent(event){
     this.filterTransactionByDate(event);
  }

  changeSource(change_event) {
    this.getExpense();
  }
  
  filterTransactionByDate(event) {
    this.service.filteredDate.subscribe(childDate => this.childDate = childDate);
    this.filteredParentDate = this.childDate;
    this.date = this.datepipe.transform(this.filteredParentDate, 'yyyy-MM-dd');
    this.getExpense();
  }

  getExpense(){
    this.tabledata = {newdate: this.date, cust_of: this.expenseof}
    this.service.readExpense(this.tabledata).subscribe(( transactions:ETransaction[] ) => {
      this.transactions = transactions;
    })
  }
 
}