import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, switchMap,debounceTime } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ETransaction } from  '../Modules/tables';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CreateCredComponent } from '../create-cred/create-cred.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { LedgerService } from '../services/ledger.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export interface Banks {
  acname: string;
  acnum: string;
}


@Injectable({
  providedIn: "root"
})

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},],
})

export class ExpensesComponent implements OnInit {

  constructor(private service: ApiService, private LS : LedgerService, public dialog: MatDialog, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.bankAccounts = this.expensesForm.controls['bankAccount'].valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap(value => this.doBankFilter(value))
    );

    this.filteredOptions = this.expensesForm.controls['beneficiaryName'].valueChanges.pipe(     // In filteredOptions array store observable of form control customerName
    startWith(""),                                                                          // Trigger event everytime form control's value changes
    debounceTime(300),                                                                  
    switchMap(value => this.doFilter(value))
    );

    this.saapatOptions = this.expensesForm.controls['saapatName'].valueChanges.pipe(     // In filteredOptions array store observable of form control customerName
    startWith(""),                                                                          // Trigger event everytime form control's value changes
    debounceTime(300),                                                                  
    switchMap(value => this.doSaapatFilter(value))
    );
  
  }

  bankAccounts: Observable<string>;
  items: string[] = ['Cash','Cheque/DD/Transfer','Bank Deposit'];
  public paymentmode: string;
  otherIncomes: string;
  bene:boolean = true;
  saapat: boolean = true;
  filteredOptions: Observable<string>;
  saapatOptions: Observable<string>;
  expense_type: string[] = []
  expense_of: string[] = ['Office', 'NHIU', 'TIU']
  banks: Banks[];
  accounts: any[];

  doFilter(value){                                                  //Get customers list
    return this.service.getCreditors().pipe(map(response =>response.filter(option => {
    return option.cred_name.indexOf(value) === 0;} )));
  }

  doBankFilter(value){
    return this.service.getBankData().pipe(map(response => response.filter(
      option => {
        return option.bank_name.indexOf(value) === 0;})));
  }

  doSaapatFilter(value){                                                  //Get customers list
    return this.service.readSaapatList().pipe(map(response => response.filter(option => {
    return option.sp_name.indexOf(value) === 0;})));    
  }

  displayFn(option): string  {
    return option && option.bank_name ? option.bank_name+' | '+option.bank_acholder: '';
  }
  
  expensesForm =      new FormGroup({
    expenseDate:      new FormControl(new Date(), Validators.required),
    expenseOf:        new FormControl('', Validators.required),
    expenseType:      new FormControl('', Validators.required),
    beneficiaryName:  new FormControl('Not Applicable', Validators.required),
    amountSpent:      new FormControl('', Validators.required),
    paymentMode:      new FormControl('', Validators.required),
    bankAccount:      new FormControl('Not Applicable', Validators.required),
    expenseRemark:    new FormControl('', Validators.required),
    saapatName:       new FormControl('Not Applicable', Validators.required),
  });
  
  createNewCreditor(): void {
    const dialogRef = this.dialog.open( CreateCredComponent , { width: '40vw', height: '80vh', disableClose: true, panelClass: "foo" } ); 
  }

  onReset(){
    this.expensesForm.reset();
    this.expensesForm.controls.beneficiaryName.patchValue['Not Applicable'];
  }
  
  doETransaction(){
    this.service.addETransaction(this.expensesForm.value).subscribe((transaction: ETransaction)=>{
      this.openSuccessSnackBar('Success! Transaction is comeplete.', 'Close')
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');},
    );
    this.onReset();
  }

  useChequeDate({value}){
    if(value == 'Cheque/DD/Transfer'){
      this.otherIncomes = "Cheque";
    }
    else {
      if(value == 'Bank Deposit'){
        this.otherIncomes = "Bank";
        this.expensesForm.patchValue({
          bankAccount : 'Not Applicable'
        })
      }
      else{
        this.otherIncomes = "Cash";
        this.expensesForm.patchValue({
          bankAccount : 'Not Applicable'
        })
      }
    }
  }

  ExpType({value}){
    if(value == 'Office'){
      this.expense_type = ['Bhatta Dasti', 'Bhatta Hafta', 'Creditor Payment', 'Currency Exchange','Staff/Labour Payment','Saapat', 'Vehicle Repairs', 'JCB Repairs', 
                            'Office Expenses', 'Miscellaneous'];
      this.bene = true;
    }
    else{
      if(value == 'NHIU' || value == 'TIU'){
        this.expense_type = ['Bhatta Dasti','Creditor Payment','Staff/Labour Payment', 'Saapat','Vehicle Repairs', 'JCB Repairs'];
        this.bene = true;
      }
    }
  }

  useBene({value}){
    if(value == 'Creditor Payment'){
      this.bene = false;
      this.saapat = true;
      this.expensesForm.patchValue({
        saapatName: 'Not Applicable'
      })
    }
    else{
      if( value == 'Saapat'){
        this.bene = true;
        this.saapat = false;
        this.expensesForm.patchValue({
          beneficiaryName : 'Not Applicable'
        })
      }
      else{
        this.bene = true;
        this.saapat = true;
        this.expensesForm.patchValue({
          beneficiaryName : 'Not Applicable',
          saapatName: 'Not Applicable'
        })
      }
    }
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    let er_snack = this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
    er_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  openSuccessSnackBar(message: string, action: string){
    let su_snack = this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
    su_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/purexp')}); 
  }

}