<div style="background-color: #0B0C12;color:white; padding:1%;">
    <h2 mat-dialog-title>Create New Creditor/Borrower</h2>
</div>

<div mat-dialog-content>

    <form [formGroup]="newCreditor" (ngSubmit)="addNewCreditor()">

        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="2%" style="margin:2%">
            
            <mat-form-field class="customer" appearance="outline">
                <mat-label>Creditor/Borrower (Saapat) Name</mat-label>
                <input type="text" matInput formControlName="name">
            </mat-form-field> 

            <div fxLayout="row" fxLayoutGap="1%">

                <mat-form-field appearance="outline" fxFlex="60">
                    <mat-label>Creditor Of</mat-label>
                    <mat-select formControlName="cred_of">
                            <mat-option *ngFor="let choice of cred_of" [value]="choice">
                                {{choice}}
                            </mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field appearance="outline" fxFlex="40">
                    <mat-label>Beneficiary Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let choice of typeof" [value]="choice">
                            {{choice}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input type="text" matInput formControlName="number" >
            </mat-form-field> 

            <mat-form-field appearance="outline">
                <mat-label>Opening Balance</mat-label>
                <input type="text" matInput formControlName="opening" >
                <mat-hint style="margin-top: 3%;">If amount is credit, input positive number. If amount is debit, input negative number.</mat-hint>
            </mat-form-field> 

            <div fxLayout="row" fxLayoutAlign="space-around center" style="padding-top:2%;">
                <button mat-raised-button color="warn" class="submitbutton" [disabled]="!newCreditor.valid"> Submit </button>
                <button type="button" mat-raised-button color="warn" class="button" (click)="onReset()"> Reset Fields </button>
            </div>

        </div>

    </form>
</div>

<div mat-dialog-actions style="margin-top: 2%; border-top: 1px solid black;">
    <button type="button" class="btn btn-outline-dark" [mat-dialog-close]="true">Close</button>
</div>