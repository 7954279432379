<mat-card class="income-card">
    
    <mat-card-title style="color:white; background-color: #0B0C12; padding:2%; border-top-left-radius: 5px; border-top-right-radius: 5px;">
        Income
    </mat-card-title>
      
    <mat-card-content>

        <form name="form" [formGroup]="incomeForm" (ngSubmit)="doITransaction()">

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1%" style="padding:2%;">
                
                <div class="DATE">
                    <app-correct-date [title]="'Select Date'" formControlName="incomeDate"></app-correct-date>
                </div>

                <div class="CUST">
                    <div fxLayout="row" fxLayoutGap="1%">

                        <mat-form-field class="customer" appearance="outline" fxFlex="92%">
                            <mat-label>Select Customer</mat-label>
                            <input type="text" matInput [matAutocomplete]="auto" formControlName="customerName">
                            <mat-autocomplete #auto="matAutocomplete" >
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.cust_name">
                                {{option.cust_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field> 

                        <button type="button" class="custbutton" (click)="createNewCustomer()" fxFlex="8%" >
                            <i class="fas fa-plus"></i>
                        </button>

                    </div>
                </div>

                <div class="AMOUNT">
                    <div fxLayout="row" fxLayoutGap="1%">
                        <mat-form-field class="amtRec" appearance="outline" fxFlex="80%">
                            <mat-label>Amount Received</mat-label>
                            <input type="text" matInput formControlName="amountReceived">
                        </mat-form-field>
                    
                        <mat-form-field class="incRefSlip" appearance="outline" fxFlex="20%">
                            <mat-label>Ref Slip No.</mat-label>
                            <input type="text" matInput formControlName="incRefSlip" >
                        </mat-form-field>
                    </div>
                </div>

                <div [ngSwitch]="otherIncomes" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1%">

                    <div fxLayout="row" fxLayoutGap="1%">

                        <mat-form-field class="MOP" appearance="outline" fxFlex="50">
                            <mat-label>Payment Mode:</mat-label>
                            <mat-select formControlName="paymentMode" (selectionChange)="useChequeDate($event)">
                                <mat-option *ngFor="let item of items" [value]="item" >
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <app-correct-date *ngSwitchCase="'Cheque'" [title]="'Select Date'" formControlName="chequeDate" fxFlex="50"></app-correct-date>

                        <mat-form-field *ngSwitchCase="'Bank'" class="customer" appearance="outline" fxFlex="50">
                            <mat-label>Select Bank A/C</mat-label>
                            <input type="text" matInput [matAutocomplete]="bank" formControlName="bank_account">
                            <mat-autocomplete #bank="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of bankAccounts | async" [value]="option">
                                    {{option.bank_name }} || {{ option.bank_acholder}} || {{ option.bank_acnum}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutGap="1%" *ngSwitchCase="'Cheque'" fxLayoutAlign="start center">

                        <mat-radio-group aria-label="Select an option" formControlName="chequetype" fxFlex="50" class="example-radio-group">
                            <mat-radio-button type="button" value="Person Cheque" style="margin-right: 1%;" (change)="chequeType($event)">Person Cheque</mat-radio-button>
                            <mat-radio-button type="button" value="Bank Cheque" (change)="chequeType($event)">Bank Cheque</mat-radio-button>
                        </mat-radio-group>
    
                        <mat-form-field *ngIf="incomeForm.value.chequetype == 'Person Cheque'" appearance="outline" fxFlex="50">
                            <mat-label >Enter Person Name</mat-label>
                            <input type="text" matInput formControlName = "chequeperson" > 
                        </mat-form-field>

                        <mat-form-field *ngIf="incomeForm.value.chequetype == 'Bank Cheque'" appearance="outline" fxFlex="50">
                            <mat-label>Select Bank A/C</mat-label>
                            <input type="text" matInput [matAutocomplete]="bank" formControlName="chequebank">
                            <mat-autocomplete #bank="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of bankAccounts | async" [value]="option">
                                    {{option.bank_name }} || {{ option.bank_acholder}} || {{ option.bank_acnum}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                                    
                    </div>
                    
                </div>

                <div> 

                        <mat-form-field class="remarks" appearance="outline" style="width:100%">
                        <mat-label>Order Remarks</mat-label>
                        <input type="text" matInput formControlName="incomeRemark">
                        </mat-form-field>

                </div>

                <div>

                    <div fxLayout="row" fxLayoutAlign="space-around center">
                        <button mat-raised-button class="submitbutton" [disabled]="!incomeForm.valid"> Submit </button>
                        <button type="button" mat-raised-button class="submitbutton" (click)="onReset()"> Reset Fields </button>
                    </div>

                </div>

            </div>

        </form>

    </mat-card-content>

</mat-card>