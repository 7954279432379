import { Component, OnInit} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS} from 'date.adapter';
import { DatePipe} from '@angular/common';
import {ITransaction} from '../Modules/tables'
import { Pipe, PipeTransform } from '@angular/core';
import {DaybookService} from '../services/daybook.service';
import { company_table_name } from '../Modules/tables';

@Component({
  selector: 'app-cashincome',
  templateUrl: './cashincome.component.html',
  styleUrls: ['./cashincome.component.css'],
  providers: [DatePipe,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})

export class CashincomeComponent implements OnInit {
 
  constructor(private service: DaybookService, private datepipe: DatePipe) { }  

  ngOnInit() {
    this.service.events$.forEach(event => this.catchEvent(event));
    this.service.changeTransactionOf.forEach(newSaleOf => this.incomeof = newSaleOf);
    this.service.source_change_event.forEach(change_event => this.changeSource(change_event));
  }

  tabledata: company_table_name;
  date: string;
  childDate: Date;
  incomeof: string = "Office";
  filteredParentDate: Date;
  transactions: ITransaction[];
  filteredTransactions: ITransaction[];

  displayedColumns: string[] = ['serial', 'income_date', 'customer_name', 'income_amount', 'payment_mode', 'income_remark', 'income_ref'];

  
  catchEvent(event){
    this.filterTransactionByDate(event);
  }

  changeSource(change_event) {
    this.getCashIncome();
  }

  filterTransactionByDate(event) {
    this.service.filteredDate.subscribe(childDate => this.childDate = childDate);
    this.filteredParentDate = this.childDate;
    this.date = this.datepipe.transform(this.filteredParentDate, 'yyyy-MM-dd');
    this.getCashIncome();
  }

  getCashIncome(){
    this.tabledata = {newdate: this.date, cust_of: this.incomeof}
    this.service.readCashIncome(this.tabledata).subscribe(( transactions:ITransaction[] ) => {
      this.transactions = transactions;
    })
  }

}

@Pipe({
  name:'INRCurrency'
})
export class INRCurrencyPipe implements PipeTransform {
  transform(value:number): string{

      value = Math.round(value);
      var result = value.toString().split('.');
      var lastThree = result[0].substring(result[0].length - 3);
      var otherNumbers = result[0].substring(0, result[0].length - 3);
      if (otherNumbers != '' && otherNumbers != '-')
          lastThree = ',' + lastThree;
      var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      if (result.length > 1) {
          output += "." + result[1];
      }
      return output;

  }
}