import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-income-and-sales',
  templateUrl: './income-and-sales.component.html',
  styleUrls: ['./income-and-sales.component.css']
})
export class IncomeAndSalesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
