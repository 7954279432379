<div>
  <button mat-raised-button color="warn" (click)="showIndex()" > All Customers </button>
      
  <button mat-raised-button color="warn" (click)="showIndex()" > Office Customers </button>
    
  <button mat-raised-button color="warn" (click)="showIndex()" > New Hira Customers </button>
  
  <button mat-raised-button color="warn" (click)="showIndex()" > Tata Customers </button>
</div>

<table mat-table [dataSource]="CustomersDatasource" class="mat-elevation-z8" id="indextable" 
matTableExporter #defexporter="matTableExporter" [hiddenColumns]="[0]">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="serialnum">
      <th mat-header-cell *matHeaderCellDef> S.No. </th>
      <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
  </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef> Number </th>
      <td mat-cell *matCellDef="let element"> {{element.number}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> Address </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="custof">
        <th mat-header-cell *matHeaderCellDef> Customer Of </th>
        <td mat-cell *matCellDef="let element"> {{element.custof}} </td>
      </ng-container>
  
    <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef> Current Balance </th>
        <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="customerList"></tr>
    <tr mat-row *matRowDef="let row; columns: customerList;"></tr>
  </table>
  
  