import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITransaction,Customer, SalesTransaction, Cheques,SentID, ETransaction, CDTransaction, CWTransaction, BankAC, Creditor, PurTransaction } from  '../Modules/tables';
import { Observable, of, BehaviorSubject, Subject } from  'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

    constructor(private httpClient: HttpClient) { }

    monthly_data = [];
    PHP_API_SERVER = "https://piyushaswani.com/backend"; 

    //ASSIGNMENTS

    transaction_of: string = "Office";

    private dueDate = new BehaviorSubject(new Date());
    chequeCashDate = this.dueDate.asObservable();
    getDueDate(duedate: Date) {
        this.dueDate.next(duedate);
    }

    bankopts = [];

    getBankData() {
        return this.bankopts.length
        ? of(this.bankopts)
        : this.httpClient
        .get<any>(`${this.PHP_API_SERVER}/api/getbanks.php`)
        .pipe(tap(data => (this.bankopts = data)));
    }

    // Main

        private _subject = new Subject<Event>();
        events$ = this._subject.asObservable();     // observable used by main component

        getNotification(): Observable<number> {
            return this.httpClient.get<number>(`${this.PHP_API_SERVER}/api/chequesdue/chequesdue.php`);
        }

    // Main ENDS

    // Sales and Income -

        opts = [];                                     // List of customers     

        getData() {                                   // List of customers
            return this.httpClient.get<any>(`${this.PHP_API_SERVER}/api/incomeandsales/readcust.php`)
            .pipe(tap(data => (this.opts = data))); 
        }

        getCustomerOf(customer_name) {
            return this.httpClient.get<any>(`${this.PHP_API_SERVER}/api/incomeandsales/readcust.php`)
            .pipe(map(results => results.filter(filteredresult => filteredresult.cust_name == customer_name)));
        }

        addITransaction(transaction:ITransaction): Observable<ITransaction>{
            return this.httpClient.post<ITransaction>(`${this.PHP_API_SERVER}/api/incomeandsales/addincome.php`, transaction);
        }

        addSalesTransaction(salestransaction:SalesTransaction): Observable<SalesTransaction>{
            return this.httpClient.post<SalesTransaction>(`${this.PHP_API_SERVER}/api/incomeandsales/addsales.php`, salestransaction);
        }

        updateInventory(transaction:SalesTransaction):Observable<any>{
            return this.httpClient.put<any>(`${this.PHP_API_SERVER}/api/incomeandsales/addinventory.php`, transaction)
        }

        createCustomer(customer:Customer): Observable<Customer>{
            return this.httpClient.post<Customer>(`${this.PHP_API_SERVER}/api/incomeandsales/createcust.php`, customer)
        }

    // Sales and Income ENDS

    // Expenses and Purchase -

    createCreditor(creditor:Creditor): Observable<Creditor>{
        return this.httpClient.post<Creditor>(`${this.PHP_API_SERVER}/api/expandpur/createcred.php`, creditor)
    }

    addETransaction(transaction:ETransaction): Observable<ETransaction>{ //expenses transaction
        return this.httpClient.post<ETransaction>(`${this.PHP_API_SERVER}/api/expandpur/addexpenses.php`, transaction);
    }

    addPurchase(transaction:PurTransaction): Observable<any>{ //purchase transaction
        return this.httpClient.post<PurTransaction>(`${this.PHP_API_SERVER}/api/expandpur/addpurchase.php`, transaction);
    }

    creditors = [];

    getCreditors() {                                   // List of creditors
        return this.httpClient.get<any>(`${this.PHP_API_SERVER}/api/expandpur/readcreditors.php`) 
        .pipe(tap(data => (this.creditors = data))); 
    }

    saapat = [];

    readSaapatList() {                                                                          //For expenses forms
        return this.httpClient.get<any>(`${this.PHP_API_SERVER}/api/saapat/saapatlist.php`)
        .pipe(tap(data => (this.saapat = data))); 
    }

    // Expenses and Purchase ENDS

    // Withdrawls & Deposits

        addCWTransaction(transaction:CWTransaction): Observable<CWTransaction>{ //cash withdrawl transaction
            return this.httpClient.post<CWTransaction>(`${this.PHP_API_SERVER}/api/depositandwithdrawl/withdrawl.php`, transaction);
        }

        addCDTransaction(transaction:CDTransaction): Observable<CDTransaction>{ //cash deposit transaction
            return this.httpClient.post<CDTransaction>(`${this.PHP_API_SERVER}/api/depositandwithdrawl/deposit.php`, transaction);
        }

        createBankAccount(bankac:BankAC): Observable<BankAC>{
            return this.httpClient.post<BankAC>(`${this.PHP_API_SERVER}/api/depositandwithdrawl/createbl.php`, bankac)
        }

    // Withdrawls & Deposits ENDS

    // Cheques and Cheque Dialog

        private chequeDueId = new BehaviorSubject(0);
        userChequeId = this.chequeDueId.asObservable();     
        getChequeId(selectedId: number){                    // get id from the selected row to be 'cashed' in cheque-due component
            this.chequeDueId.next(selectedId);              // And push the id as observable --> userChequeId --> for ChequeDialogComponent
        }

        cashOut(selectedId:SentID): Observable<any>{        //Cash out cheque
            return this.httpClient.put<any>(`${this.PHP_API_SERVER}/api/chequesdue/cashcheque.php`, selectedId);
        }
        
        private chequesubject = new Subject<Event>();
        cheque_cash_event = this.chequesubject.asObservable();  // Serve as observable on main component so that any time a cheque is cashed, notification is reduced
        newChequeEvent(chequeEvent:Event) {                     // Called by chequedialog after cashing cheque
            this.chequesubject.next(chequeEvent);
        }

        showCheques(): Observable<Cheques[]> {                                                  // Observable of all cheques filtered by their 'cashed' condition
            return this.httpClient.get<any>(`${this.PHP_API_SERVER}/api/chequesdue/cheques.php`);       //.pipe(map(result => result.filter(filtered => filtered.cstatus === 'Not Cashed')));
        }

        oldCheques(): Observable<Cheques[]> {                                                  
            return this.httpClient.get<any>(`${this.PHP_API_SERVER}/api/chequesdue/oldcheques.php`);
        }

        getChequesByDate(duedate:string): Observable<Cheques[]> { 
            return this.httpClient.get<Cheques[]>(`${this.PHP_API_SERVER}/api/chequesdue.php`).
            pipe(map(result => result.filter(filtered => filtered.cd_cashoutdate === duedate)));
        }

    // Cheques and Cheque Dialog ENDS

    //
    //    readCustomerList(): Observable<Customer[]> {
    //        return this.httpClient.get<Customer[]>(`${this.PHP_API_SERVER}/api/read.php`);
    //    }
        

}