<script src="https://kit.fontawesome.com/52d226537c.js" crossorigin="anonymous"> </script>
<link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css">
<link href="https://fonts.googleapis.com/css?family=Pacifico&display=swap" rel="stylesheet"> 
<div class="main-container" fxLayout="column" style="height:100%;">

    <div fxFlex="10" class="header-div">
        <mat-toolbar style="height:100%; background-color: #950E10;">

            <button mat-icon-button (click)="sidenav.toggle()" style="color: white;"> 
                <mat-icon>menu</mat-icon> 
            </button>

            <button mat-icon-button (click)="logout()" style="color: white;">
                <mat-icon>power_settings_new</mat-icon>
            </button>

            <span class="fill-remaining-space"></span>
            <span class="homenav" style="color: white;">
                Brick Industires Accounting Software - BIAS
            </span>
        
        </mat-toolbar>
    </div>

    <div class="body-container" fxFlex="90" >

        <mat-sidenav-container class="sidenav-container" style="height:100%;">
            
                <mat-sidenav #sidenav mode="over" opened="false" disableClose>

                    <mat-nav-list class="dashboard">

                        <a mat-list-item routerLink="salesandincome" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">attach_money</mat-icon>
                            <p matLine>Add Sales and Income</p>
                        </a>
            
                        <a mat-list-item routerLink="purexp" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon"> shop</mat-icon>
                            <p matLine>Add Purchase And Expenses </p>
                        </a>

                        <a mat-list-item routerLink="depwith" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">savings</mat-icon>
                            <p matLine>Add Deposits And Withdrawls </p>
                        </a>

                        <mat-divider class="divider"></mat-divider>

                        <a mat-list-item routerLink="daybook" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">assignment</mat-icon>
                            <p matLine>View Daybook</p>
                        </a>

                        <a mat-list-item routerLink="customerledger" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">assignment_ind</mat-icon>
                            <p matLine>View Customer Ledgers</p>
                        </a>

                        <a mat-list-item routerLink="creditorsledger" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon"><fa-icon [icon]="faBook"></fa-icon></mat-icon>
                            <p matLine>View Creditors' Ledgers</p>
                        </a>

                        <a mat-list-item routerLink="saapat" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">redo</mat-icon>
                            <p matLine>Saapat</p>
                        </a>

                        <mat-divider></mat-divider>

                        <a mat-list-item routerLink="inventory" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">book_outlined</mat-icon>
                            <p matLine>View Inventory</p>
                        </a>

                        <a mat-list-item routerLink="chequesdue" routerLinkActive="active-list-item">
                            <mat-icon matBadge="{{notification}}" [matBadgeHidden]="showNotification" matBadgeColor="warn" mat-list-icon class="menu-icon">check_box</mat-icon>
                            <p matLine>Cheques/DD Due</p>
                        </a>

                        <a mat-list-item routerLink="finance" routerLinkActive="active-list-item">
                            <mat-icon mat-list-icon class="menu-icon">assessment</mat-icon>
                            <p matLine>Finance Report</p>
                        </a>

                        <mat-divider></mat-divider>

                    </mat-nav-list>
                
                </mat-sidenav>

                <mat-sidenav-content>

                    <mat-sidenav-container class="sidenav-container" style="height:100%;">

                        <mat-sidenav #sidenav mode="side" opened="true" disableClose fxShow fxHide.xs>

                            <mat-nav-list class="dashboard" >

                                <a mat-list-item routerLink="salesandincome" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon >attach_money</mat-icon>
                                </a>

                                <a mat-list-item routerLink="purexp" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon"> shop</mat-icon>
                                </a>
        
                                <a mat-list-item routerLink="depwith" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon">savings</mat-icon>
                                </a>

                                <mat-divider></mat-divider>
        
                                <a mat-list-item routerLink="daybook" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon">assignment</mat-icon>
                                </a>
        
                                <a mat-list-item routerLink="customerledger" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon">assignment_ind</mat-icon>
                                </a>

                                <a mat-list-item routerLink="creditorsledger" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon"><fa-icon [icon]="faBook"></fa-icon></mat-icon>
                                </a>

                                <a mat-list-item routerLink="saapat" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon">redo</mat-icon>
                                </a>

                                <mat-divider></mat-divider>

                                <a mat-list-item routerLink="inventory" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon">book</mat-icon>
                                </a>

                                <a mat-list-item routerLink="chequesdue" routerLinkActive="active-list-item">
                                    <mat-icon matBadge="{{notification}}" [matBadgeHidden]="showNotification" matBadgeColor="warn" mat-list-icon class="menu-icon">check_box</mat-icon>
                                </a>
        
                                <a mat-list-item routerLink="finance" routerLinkActive="active-list-item">
                                    <mat-icon mat-list-icon class="menu-icon">assessment</mat-icon>
                                </a>

                                <mat-divider></mat-divider>
                
                            </mat-nav-list>
                        </mat-sidenav>

                        <mat-sidenav-content style="background-color: #e5e5e5;">
                            <div class="router-div">
                                <router-outlet></router-outlet>
                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>

                </mat-sidenav-content>

        </mat-sidenav-container>

    </div>
    
</div>