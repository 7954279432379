import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import {Observable} from 'rxjs';
import {map, startWith, switchMap, tap, debounceTime} from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ITransaction } from  '../Modules/tables';
import {CreatecustComponent} from '../createcust/createcust.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};


@Injectable({
  providedIn: "root"
})

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css'],
  providers: [ 
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class IncomeComponent implements OnInit {

  constructor(private service: ApiService, public dialog: MatDialog, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar, private router: Router) { }  

  createNewCustomer(): void {
    const dialogRef = this.dialog.open( CreatecustComponent , { width: '40vw', height: '80vh', disableClose: true, panelClass: "foo" } ); 
  }

  otherIncomes: string;
  showChequeDate: boolean = false;
  showBankAccount: boolean = false;
  transactions: ITransaction[];
  items: string[] = ['Cash','Cheque/DD','Bank Deposit'];
  public paymentmode: string;
  quantity: number;
  rate: number;
  price():number {return this.quantity * this.rate};

  options = [];
  filteredOptions: Observable<string>;
  bankAccounts: Observable<string>; 

  incomeForm = new FormGroup({
    customerName: new FormControl('', Validators.required),
    incomeDate: new FormControl('', Validators.required),
    incomeRemark: new FormControl('', Validators.required),
    incRefSlip: new FormControl('', Validators.required),
    paymentMode: new FormControl('', Validators.required),
    chequeDate: new FormControl('00-00-0000', Validators.required),
    amountReceived: new FormControl('', Validators.required),
    bank_account: new FormControl('Not Applicable', Validators.required),
    chequetype: new FormControl('Not Applicable', Validators.required),
    chequeperson: new FormControl('Not Applicable', Validators.required),
    chequebank: new FormControl('Not Applicable', Validators.required)
  });


  ngOnInit() {
    this.filteredOptions = this.incomeForm.controls['customerName'].valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap(value => this.doFilter(value))
    );
    this.bankAccounts = this.incomeForm.controls['bank_account'].valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap(value => this.doBankFilter(value))
    );
    this.otherIncomes = "None";
  }

  doFilter(value){
    return this.service.getData().pipe(map(response =>response.filter(option => {
    return option.cust_name.indexOf(value) === 0;})));
  }

  doBankFilter(value){
    return this.service.getBankData().pipe(map(response =>response.filter(option => {
    return option.bank_name.indexOf(value) === 0;})));
  }

  doITransaction(){
    this.service.addITransaction(this.incomeForm.value).subscribe((transaction: ITransaction)=>{
      this.openSuccessSnackBar('Success! Transaction is comeplete.', 'Close')
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');},
    );
    this.onReset();
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    let er_snack = this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
    er_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }
  openSuccessSnackBar(message: string, action: string){
    let su_snack = this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
    su_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  useChequeDate({value}){
    if(value == 'Cheque/DD'){
      this.otherIncomes = "Cheque";
      this.incomeForm.patchValue ({
        bank_account: 'Not Applicable'
      });
    }
    else{
      if(value == 'Bank Deposit'){
        this.otherIncomes = "Bank";
        this.incomeForm.patchValue ({
          chequeDate: '00-00-0000',
          chequetype: 'Not Applicable',
          chequeperson: 'Not Applicable',
          chequebank: 'Not Applicable'
        });
      }
      else{
        this.otherIncomes = "Cash";
        this.incomeForm.patchValue ({
          chequeDate: '00-00-0000',
          chequetype: 'Not Applicable',
          chequeperson: 'Not Applicable',
          chequebank: 'Not Applicable',
          bank_account: 'Not Applicable'
        });
      }
    }
  }


  onReset(){
    this.incomeForm.setValue ({
      customerName: '' ,
      incomeDate: '',
      incomeRemark: '',
      incRefSlip: '',
      paymentMode: '',
      chequeDate: '00-00-0000',
      amountReceived: '',
      bank_account: 'Not Applicable',
      chequetype: 'Not Applicable',
      chequeperson: 'Not Applicable',
      chequebank: 'Not Applicable'
    })
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/salesandincome')}); 
  }
  
  ct:string;
  
  chequeType(event) {
    this.ct = event.value;
    if(this.ct == 'Person Cheque'){
      this.incomeForm.patchValue ({
        chequebank: 'Not Applicable',
      });
    }
    else {
      this.incomeForm.patchValue ({
        chequeperson: 'Not Applicable',
      });
    }
  }

  displayFn(option): string  {
    return option && option.bank_name ? option.bank_name+' | '+option.bank_acholder: '';
  }
  
}