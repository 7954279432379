<div class="incomesales" fxLayout="column" style="padding:1%; background-color: #e5e5e5;">

    <div class="header" fxFlex="10" style="padding:1%" fxLayout="row" fxLayoutAlign="start center">
        <img src="../../assets/financial.png" style="height:64px; width:64px;">
        <h3 style="margin-left:1%; font-weight: bold; font-size: 300%" [ngStyle.lt-sm]="{'font-size.em':2.5}">Sales & Income</h3>
    </div>

    <div fxFlex="90">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%">
            <app-sales fxFlex="50"></app-sales> 
            <app-income fxFlex="50"></app-income>
        </div>
    </div>
    
</div>