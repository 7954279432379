import { Component, OnInit, ViewChild } from '@angular/core';
import {CredLedger, Creditor} from '../Modules/tables'
import { trigger, style, transition, animate} from '@angular/animations';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {formatDate} from '@angular/common';
import {ExcelService} from '../services/excel.service';
import { LedgerService } from '../services/ledger.service';
import {MatDialog} from '@angular/material/dialog';
import { CredLedgerCorrectionComponent } from '../cred-ledger-correction/cred-ledger-correction.component';

@Component({
  selector: 'app-creditors-ledger',
  templateUrl: './creditors-ledger.component.html',
  styleUrls: ['./creditors-ledger.component.css'],
  animations: [
    trigger('slideLeftToRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms 300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideRightToLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms 300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})

export class CreditorsLedgerComponent implements OnInit {
  

  constructor(private service: LedgerService, private excelService:ExcelService, public dialog: MatDialog) { }  

  ngOnInit() {
  }

  credname: string;

  openDialog(): void {
    const dialogRef = this.dialog.open(CredLedgerCorrectionComponent, {
      width: '500px',
      data: {name: this.creditorName},
    });
    
  }

  CredLedgerDataSource = new MatTableDataSource<CredLedger>();
  private paginator: MatPaginator

@ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
  this.paginator = mp;
  this.setDataSourceAttributes();
  }

  credDBName: any;
  cred_of_circle: string;
  deflistfilename: string;
  today =  formatDate(new Date(), 'dd-MM-YYYY', 'en', '+0530');
  creditor_of: string;
  isVisible = true;
  creditorName: string;
  ledgerBalance: number;
  showDef:boolean = false;
  printDefButton:boolean = true;
 
  element: HTMLElement;
  credList: Creditor[];
  emptyDataSource: Creditor[];
  credLedger: CredLedger[];
  ledgerColumns: string[] = ['serial', 'crl_date', 'crl_ref', 'crl_part', 'crl_debit', 'crl_credit', 'crl_balance'];
  creditorList: string[] = ['serialnum', 'cred_name','cred_num', 'cred_of', 'current_balance'];
  alphabet: string[] = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

  selectedLetter({value}){
    console.log(value);
    this.beginsWith = value;
    this.service.showLedgerCredList(this.beginsWith).subscribe((credList: Creditor[])=>{
      this.credList = credList;
      this.dataSource.data = credList;
      console.log(this.credList);})
  }

public beginsWith = "";
public custDBName;
public defaulterList;
dataSource = new MatTableDataSource<Creditor>()

showCredList(myBtn: HTMLButtonElement){
  this.beginsWith = myBtn.innerHTML;
  console.log(this.beginsWith);
  this.service.showLedgerCredList(this.beginsWith).subscribe((credList: Creditor[])=>{
      this.credList = credList;
      this.dataSource.data = credList;
      console.log(this.credList);})
}

openLedger(row){
    this.isVisible = !this.isVisible;
    this.creditorName = row.cred_name;
    console.log(this.creditorName);
    this.credDBName = row.cred_name.replace(/ /g, "_").toLowerCase();
    const ledgerJSON = {
      dbname: this.credDBName,
    };
    this.service.showCredLedger(ledgerJSON).subscribe((credLedger: CredLedger[])=>{
    this.credLedger = credLedger;
    this.CredLedgerDataSource.data = credLedger;
    console.log(this.credLedger);
    })
    this.service.CredLedgerBalance(ledgerJSON).subscribe((ledgerBalance:number)=>{
      this.ledgerBalance = ledgerBalance;
      console.log(ledgerBalance);
    })
    this.customerOf(this.creditorName);
}

customerOf(cred_name){
  
  const cn = {
    credname: cred_name,
  };

  console.log(cn);
  this.service.getCredOf(cn).subscribe((creditorof:string)=>{
    this.creditor_of = creditorof;
    console.log(this.creditor_of);

    if(this.creditor_of == 'Office'){
      this.cred_of_circle = 'OFF';
      console.log(this.cred_of_circle);
    }

    else{
      this.cred_of_circle = this.creditor_of;
    }
    
  })
}

  showIndex(){
    this.showDef = !this.showDef;
    this.printDefButton = !this.printDefButton;
    this.dataSource.data = this.emptyDataSource;
    console.log(this.emptyDataSource);
  }

  setDataSourceAttributes() {
    this.CredLedgerDataSource.paginator = this.paginator;
  }

  goBack(){
    this.isVisible = !this.isVisible;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportDefaulters():void {
    this.excelService.exportAsExcelFile(this.defaulterList, this.deflistfilename);
  }
  exportLedger():void {
    this.excelService.exportAsExcelFile(this.defaulterList, this.deflistfilename);
  }

}


