<div fxLayout="column" style="padding:1%; background-color: #e5e5e5">

    <div class="DaybookHeader" fxFlex="10" style="padding:1%" fxLayout="row" fxLayoutAlign="space-between center">
        
        <div fxLayout="row">
            <img src="../../assets/bookkeeping.png" style="height:64px; width:64px;">
            <h3 style="margin-left:1%; font-weight: bold; font-size: 300%" [ngStyle.lt-sm]="{'font-size.em':2.5}">Daybook</h3>
        </div>

        <div class="FILTER DATE">
            <form name="form" [formGroup]="daybookFilterForm" fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="start stretch">
                <mat-form-field appearance="outline" [ngStyle.lt-sm]="{'width.px':150}">
                    <mat-label>Select Date</mat-label>
                    <input matInput [matDatepicker]="ledgerdate" formControlName ="sDate" (dateInput)="dateSend()">
                    <mat-datepicker-toggle matSuffix [for]="ledgerdate"> </mat-datepicker-toggle>
                    <mat-datepicker #ledgerdate></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button class="submitbutton" [disabled]="enable_button" (click)="sendEvent($event)"> 
                    <mat-icon fxHide.gt-sm>keyboard_return</mat-icon>
                    <span fxHide.lt-sm>Show Daybook</span> 
                </button>
            </form>
        </div>

    </div>

    <div class="BODY" [hidden]="hidebody" style="margin:0 .5%" fxFlex fxLayout="column" fxLayoutAlign="start stretch">

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="2%" style="padding:1%">

                <div class="FIGURES" fxLayout="column" fxLayoutGap="2%" style="margin:1%; padding:2%; background-color: white; border-radius: 5px;" fxFlex.gt-sm="50">

                    <div class="FIGURES_1" fxLayout="row" fxLayoutGap="10%">

                        <div fxFlex class="figures" fxLayout="column" fxLayoutAlign="space-around center" style="background-color:#90EE90;">

                            <img src="assets/safebox.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">

                            <div fxLayout="column" fxLayoutAlign="space-between center"> 
                                <div style="font-size: 35px; font-weight: bold;" [ngStyle.xs]="{'font-size.px':25}">Rs. {{CIH | INRCurrency}}</div>
                                <div style="font-size: 16px; color:grey;" [ngStyle.xs]="{'font-size.px':12}">Cash in hand</div>
                            </div>

                        </div>

                        <div fxFlex class="figures" fxLayout="column" fxLayoutAlign="space-around center" style="background-color: #FBCEB1;">
                                
                            <img src="assets/pricetag.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">

                            <div fxLayout="column" fxLayoutAlign="space-between center"> 
                                <div style="font-size: 35px; font-weight: bold;" [ngStyle.xs]="{'font-size.px':25}">Rs. {{totalSales | INRCurrency}}</div>
                                <div style="font-size: 16px; color:grey;" [ngStyle.xs]="{'font-size.px':12}">Total Sales</div>
                            </div>

                        </div>

                    </div>

                    <div class="FIGURES_2" fxLayout="row" fxLayoutGap="10%">

                        <div fxFlex class="figures" fxLayout="column" fxLayoutAlign="space-around center" style="background-color: #98FB98;">
                                
                            <img src="assets/cashregister.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">

                            <div fxLayout="column" fxLayoutAlign="space-between center"> 
                                <div style="font-size: 35px; font-weight: bold;" [ngStyle.xs]="{'font-size.px':25}">Rs. {{totalIncome | INRCurrency}}</div>
                                <div style="font-size: 16px;color:grey;" [ngStyle.xs]="{'font-size.px':12}">Total Revenue</div>
                            </div>
                        </div>

                        <div fxFlex class="figures" fxLayout="column" fxLayoutAlign="space-around center" style="background-color: #E9967A;">
                                            
                            <img src="assets/wallet.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">

                            <div fxLayout="column" fxLayoutAlign="space-between center"> 
                                <div style="font-size: 35px; font-weight: bold;" [ngStyle.xs]="{'font-size.px':25}">Rs. {{totalExpense | INRCurrency}}</div>
                                <div style="font-size: 16px;color:grey;" [ngStyle.xs]="{'font-size.px':12}">Total Expenditure</div>
                            </div>
                        </div>

                    </div>

                    <div class="FIGURES_3" fxLayout="row" fxLayoutGap="10%">

                        <div fxFlex class="figures" fxLayout="column" fxLayoutAlign="space-around center" style="background-color: #F0E68C;">
                                            
                            <img src="assets/piggy.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">

                            <div fxLayout="column" fxLayoutAlign="space-between center"> 
                                <div style="font-size: 35px; font-weight: bold;" [ngStyle.xs]="{'font-size.px':25}">Rs. {{totalDeposit | INRCurrency}}</div>
                                <div style="font-size: 16px;color:grey;" [ngStyle.xs]="{'font-size.px':12}">Total Deposits</div>
                            </div>
                        </div>

                        <div fxFlex class="figures" fxLayout="column" fxLayoutAlign="space-around center" style="background-color: #B2FFFF;">
                            
                            <img src="assets/atm.png" width="64px" height="64px" [ngStyle.xs]="{'width.px':32, 'height.px':32}">

                            <div fxLayout="column" fxLayoutAlign="space-between center"> 
                                <div style="font-size: 35px; font-weight: bold;" [ngStyle.xs]="{'font-size.px':25}">Rs. {{totalWithdrawl | INRCurrency}}</div>
                                <div style="font-size: 16px;color:grey;" [ngStyle.xs]="{'font-size.px':12}">Total Withdrawl</div>
                            </div>

                        </div>

                    </div>

                </div>
                
                <div class="SUMMARY" style="margin:1%; padding:2%; background-color: white; border-radius: 5px;" fxFlex.gt-sm="50">

                    <div fxLayout="column" fxLayoutGap="1%" class="summary_div">

                        <div class="summaryh4" fxLayout="row" fxLayoutAlign="start center">
                            <img src="assets/bar-graph.png" width="32px" height="32px" [ngStyle.xs]="{'width.px':16, 'height.px':16}">
                            <h4>Summary Of The Day</h4>
                        </div>

                        <div fxFlex class="summary">
                            <mat-accordion >
                                <mat-expansion-panel hideToggle style="margin-top:2%;">

                                    <mat-expansion-panel-header class="sales_summary">
                                            <mat-panel-title>
                                                Office
                                            </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="sales_summary_ep"><mat-icon>receipt</mat-icon>Sales Summary</div>

                                    <table class="reports_table" style="background-color:#F3D3D7; margin-top: 1px;">
                                        <tr><td>Avval Bricks</td><td>{{avval_office}} Units</td></tr>
                                        <tr><td>Meetha Bricks</td><td>{{meetha_office}} Units</td></tr>
                                        <tr><td>Avval Tukra </td><td>{{tukra_office}} Trolley</td></tr>
                                        <tr><td>Raanth Bricks</td><td>{{raanth_office}} Units</td></tr>
                                        <tr><td>Godiya Bricks</td><td>{{godiya_office}} Units</td></tr>
                                        <tr><td>Sema Bricks</td><td>{{sema_office}} Units</td></tr>
                                        <tr><td>Meetha Tukra Bricks</td><td>{{meetha_tukra_office}} Units</td></tr>
                                    </table>

                                    <div class="report_summary_ep"><mat-icon>account_balance_wallet</mat-icon>Revenue Summary</div>

                                    <table class="reports_table" style="background-color: #5A9E5F; margin-top: 1px;">            
                                        <tr><td>Cash Revenue</td><td>{{office_cr | INRCurrency}} Rs.</td></tr>
                                        <tr><td>Cheque Revenue</td><td>{{office_chr | INRCurrency}} Rs.</td></tr>
                                        <tr><td>Bank Transfers</td><td>{{office_bt | INRCurrency}} Rs.</td></tr>
                                    </table>

                                    <div style="background-color: #A60402; color: white; padding: 1%; margin-top: 1%;">
                                        <mat-icon>attach_money</mat-icon>Expenditure
                                    </div>
                                    <table class="reports_table" style="background-color: #D63333; margin-top: 1px;">
                                        <tr><td>Cash Expense</td><td>{{office_ce | INRCurrency}} Rs.</td></tr>
                                    </table>

                                    </mat-expansion-panel>
                                </mat-accordion>
                        </div>

                        <div fxFlex class="summary">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle style="margin-top:2%;">

                                    <mat-expansion-panel-header class="sales_summary">
                                        <mat-panel-title>
                                            NHIU
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="sales_summary_ep"><mat-icon>receipt</mat-icon>Sales Summary</div>

                                    <table class="reports_table" style="background-color:#F3D3D7; margin-top: 1px;">
                                        <tr><td>Avval Bricks</td><td>{{avval_nhiu}} Units</td></tr>
                                        <tr><td>Meetha Bricks</td><td>{{meetha_nhiu}} Units</td></tr>
                                        <tr><td>Avval Tukra </td><td>{{tukra_nhiu}} Trolley</td></tr>
                                        <tr><td>Raanth Bricks</td><td>{{raanth_nhiu}} Units</td></tr>
                                        <tr><td>Godiya Bricks</td><td>{{godiya_nhiu}} Units</td></tr>
                                        <tr><td>Sema Bricks</td><td>{{sema_nhiu}} Units</td></tr>
                                        <tr><td>Meetha Tukra Bricks</td><td>{{meetha_tukra_nhiu}} Units</td></tr>
                                    </table>

                                    <div class="report_summary_ep"><mat-icon>account_balance_wallet</mat-icon>Revenue Summary</div>

                                    <table class="reports_table" style="background-color: #5A9E5F; margin-top: 1px;">
                                        <tr><td>Cash Revenue</td><td>{{nhiu_cr | INRCurrency}} Rs.</td></tr>
                                        <tr><td>Cheque Revenue</td><td>{{nhiu_chr | INRCurrency}} Rs.</td></tr>
                                        <tr><td>Bank Transfers</td><td>{{nhiu_bt | INRCurrency}} Rs.</td></tr>
                                    </table>

                                    <div style="background-color: #A60402; color: white; padding: 1%; margin-top: 1%;">
                                        <mat-icon>attach_money</mat-icon>Expenditure
                                    </div>
                                    <table class="reports_table" style="background-color: #D63333; margin-top: 1px;">
                                        <tr><td>Cash Expense</td><td>{{nhiu_ce | INRCurrency}} Rs.</td></tr>
                                    </table>

                                    </mat-expansion-panel>
                                </mat-accordion>
                        </div>

                        <div fxFlex class="summary">
                                <mat-accordion style="margin-top: 2%;">
                                    <mat-expansion-panel hideToggle style="margin-top:2%;">
                                    <mat-expansion-panel-header class="sales_summary">
                                        <mat-panel-title>
                                            TIU
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="sales_summary_ep"><mat-icon>receipt</mat-icon>Sales Summary</div>

                                    <table class="reports_table" style="background-color:#F3D3D7; margin-top: 1px;">
                                        <tr><td>Avval Bricks</td><td>{{avval_tiu}} Units</td></tr>
                                        <tr><td>Meetha Bricks</td><td>{{meetha_tiu}} Units</td></tr>
                                        <tr><td>Avval Tukra </td><td>{{tukra_tiu}} Trolley</td></tr>
                                        <tr><td>Raanth Bricks</td><td>{{raanth_tiu}} Units</td></tr>
                                        <tr><td>Godiya Bricks</td><td>{{godiya_tiu}} Units</td></tr>
                                        <tr><td>Sema Bricks</td><td>{{sema_tiu}} Units</td></tr>
                                        <tr><td>Meetha Tukra Bricks</td><td>{{meetha_tukra_tiu}} Units</td></tr>
                                    </table>

                                    <div class="report_summary_ep"><mat-icon>account_balance_wallet</mat-icon>Revenue Summary</div>

                                    <table class="reports_table" style="background-color: #5A9E5F; margin-top: 1px;">
                                    <tr><td>Cash Revenue</td><td>{{tiu_cr | INRCurrency}} Rs.</td></tr>
                                    <tr><td>Cheque Revenue</td><td>{{tiu_chr | INRCurrency}} Rs.</td></tr>
                                    <tr><td>Bank Transfers</td><td>{{tiu_bt | INRCurrency}} Rs.</td></tr>
                                    </table>

                                    <div style="background-color: #A60402; color: white; padding: 1%; margin-top: 1%;">
                                        <mat-icon>attach_money</mat-icon>Expenditure
                                    </div>
                                    <table class="reports_table" style="background-color: #D63333; margin-top: 1px;">
                                        <tr><td>Cash Expense</td><td>{{tiu_ce | INRCurrency}} Rs.</td></tr>
                                    </table>

                                    </mat-expansion-panel>
                                </mat-accordion>
                        </div>

                    </div>

                </div>

            </div>

            <div class="TABLES">

                <div class="LIST" style="margin-bottom: 1%; padding:1%; background-color: white; border-radius: 5px;">

                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink (click)="filterOffice()">Office</a>
                        </li>
                        <li [ngbNavItem]="2" (click)="filterHira()">
                          <a ngbNavLink>NHIU</a>
                        </li>
                        <li [ngbNavItem]="3">
                          <a ngbNavLink (click)="filterTata()">TIU</a>
                        </li>
                      </ul>
                      
                      <div [ngbNavOutlet]="nav" class="mt-2"></div>

                </div>

                <div fxLayout="column" fxLayoutGap="1%">

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" style="height: 500px;">

                        <div fxFlex class="SALES REPORT" style="background-color: white; padding: .5%; border-radius: 5px;">
                            <div class="incomeheader" style="border-color: #fbceb1; background-color: #fbceb1;"> 
                                <span><em>Sales</em></span> 
                            </div>

                            <div>
                                <app-salesreport></app-salesreport>
                            </div>
                        
                        </div>

                        <div fxFlex class="INCOME REPORT" style="background-color: white; padding: .5%; border-radius: 5px;">

                            <div class="incomeheader" style="border-color: #28b463; background-color: #28b463;">
                                <span><em>Income</em></span>
                            </div>
                            
                            <div class="INCOME" style="height:430px; overflow-y: scroll;">

                                <div class="incomeheader"><span><em>Cash Income</em></span></div>
                                <app-cashincome></app-cashincome>

                                <div class="incomeheader"><span><em>Cheques Income</em></span></div>
                                <app-cheque-income></app-cheque-income>

                                <div class="incomeheader"><span><em>Transfers Income</em></span></div>
                                <app-transfers-income></app-transfers-income>

                                <div class="incomeheader"><span><em>Bank Withdrawls</em></span></div>
                                <app-withdrawl-income></app-withdrawl-income>

                            </div>

                        </div>

                    </div>    

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" style="height: 500px;" >
                        
                        <div fxFlex class="EXPENSES REPORT" style="background-color: white; padding: .5%; border-radius: 5px;">
                            <div class="incomeheader" style="background-color: #F64740; border-color:#F64740 ;">
                                <span>Expenses</span>
                            </div>
                            <app-expensereport></app-expensereport>
                        </div>

                        <div fxFlex class="DEPOSITS REPORT" style="background-color: white; padding: .5%; border-radius: 5px;">
                            <div class="incomeheader" style="background-color: #f7dc6f; border-color:#f7dc6f ;">
                                <span>Deposits</span>   
                            </div>
                            <app-deposits-report></app-deposits-report>
                        </div>

                    </div>

                </div>

            </div>

    </div>

</div>
