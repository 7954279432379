import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DaybookDate } from  '../Modules/tables';
import { Observable, BehaviorSubject, Subject } from  'rxjs';
import { map } from 'rxjs/operators';
import { ITransaction, SalesTransaction, ETransaction, CDTransaction, CWTransaction} from  '../Modules/tables';
import { company_table_name } from '../Modules/tables';


@Injectable({
  providedIn: 'root'
})
export class DaybookService {

  constructor(private http: HttpClient) { }

  PHP_API_SERVER = "https://piyushaswani.com/backend"; 
  
  private dateSource = new BehaviorSubject(new Date());
  filteredDate = this.dateSource.asObservable();
  changeFilterDate(childDate: Date) {                     //Used by daybook
      this.dateSource.next(childDate);
  }  

  private _subject = new Subject<Event>();
  events$ = this._subject.asObservable();   
  newEvent(nextevent:Event) {                 // Method used by daybook
    this._subject.next(nextevent);
}

  private filterOffice = new BehaviorSubject('Office');     //filterOffice is a Subject
  changeTransactionOf = this.filterOffice.asObservable();   // changeTransactionOf is an Observable
  filterTransactionSource(saleof: string) {                 // Function to change source of table in daybook in a stream of observable
      this.filterOffice.next(saleof);
  }

  private change_source_subject = new Subject<Event>();
  source_change_event = this.change_source_subject.asObservable();
  TransactionSourceChangeEvent(changevent:Event){
      this.change_source_subject.next(changevent);
  }

  // Daybook

  //    newEvent(nextevent:Event) {                 // Method used by daybook
  //      this._subject.next(nextevent);
  //  }

  // Daybook


  // Figures 

    getCashInHand(dateJSON: DaybookDate): Observable<number> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/figures/cashinhand.php`, dateJSON);
    }

    getTotalDeposits(dateJSON: DaybookDate): Observable<number> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/figures/totaldeposits.php`, dateJSON);
    }

    getTotalExpense(dateJSON: DaybookDate): Observable<number> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/figures/totalexpense.php`, dateJSON);
    }

    getTotalIncome(dateJSON: DaybookDate): Observable<number> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/figures/totalincome.php`, dateJSON);
    }

    getTotalSales(dateJSON: DaybookDate): Observable<number> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/figures/totalsales.php`, dateJSON);
    }

    getTotalWithdrawl(dateJSON: DaybookDate): Observable<number> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/figures/totalwithdrawl.php`, dateJSON);
    }
    
  // Figures ends

  // Summary

    getAvvalSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totalavval.php`, dateJSON);
    }

    getMeethaSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totalmeetha.php`, dateJSON);
    }
    
    getTukraSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totaltukra.php`, dateJSON);
    }
    
    getGodiyaSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totalgodiya.php`, dateJSON);
    }
    
    getRaanthSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totalraanth.php`, dateJSON);
    }
    
    getSemaSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totalsema.php`, dateJSON);
    }

    getMeethaTukraSold(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/sales/totalmeethatukra.php`, dateJSON);
    }

    getCashRevenue(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/revenue/cashrevenue.php`, dateJSON);
    }

    getChequeRevenue(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/revenue/chequerevenue.php`, dateJSON);
    }

    getBankTransfer(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/revenue/banktransfers.php`, dateJSON);
    }

    getCashExpense(dateJSON: DaybookDate): Observable<any> {
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/summary/expense/cashexpense.php`, dateJSON);
    }

  //Summary

  // Tables
  readTransferIncome(ctn: company_table_name): Observable<ITransaction[]>{
      return this.http.post<ITransaction[]>(`${this.PHP_API_SERVER}/api/daybook/tables/readtransfer.php`, ctn)
  }

  readWitIncome(date:string): Observable<CWTransaction[]>{
      return this.http.get<CWTransaction[]>(`${this.PHP_API_SERVER}/api/daybook/tables/readwit.php`).
      pipe(map(result => result.filter(filtered => filtered.wit_date === date)));
  }

  readDeposit(date:string): Observable<CDTransaction[]>{
      return this.http.get<CDTransaction[]>(`${this.PHP_API_SERVER}/api/daybook/tables/readdep.php`).
      pipe(map(result => result.filter(filtered => filtered.dep_date === date)));
  }

  readSales2(ctn: company_table_name): Observable<SalesTransaction[]>{                                           // SALES REPORT COMP
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/tables/readsales.php`, ctn)
  }

  readExpense(ctn: company_table_name): Observable<ETransaction[]>{
      return this.http.post<ETransaction[]>(`${this.PHP_API_SERVER}/api/daybook/tables/readexpense.php`, ctn)
  }

  readCashIncome(ctn: company_table_name): Observable<ITransaction[]>{
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/tables/readcash.php`, ctn)
  }

  readChequeIncome(ctn: company_table_name): Observable<ITransaction[]>{
      return this.http.post<any>(`${this.PHP_API_SERVER}/api/daybook/tables/readcheque.php`, ctn)
  }

}