<h2 mat-dialog-title>Ledger of - {{bankName}} | {{achName}}</h2>
<div mat-dialog-content style="background-color: white; border-radius: 5px; padding: 1%;">

        <table style="width: 100%;" mat-table [dataSource]="BankLedgerDataSource" class="mat-elevation-z8" matSort>
            
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef> S.No. </th>
            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>
                
        <ng-container matColumnDef="bl_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let element">{{element.bl_date | date:'dd/MM/yyyy'}} </td>
        </ng-container>
                
        <ng-container matColumnDef="bl_part">
            <th mat-header-cell *matHeaderCellDef> Particulars </th>
            <td mat-cell *matCellDef="let element"> {{element.bl_part}} </td>
        </ng-container>

        <ng-container matColumnDef="bl_debit">
            <th mat-header-cell *matHeaderCellDef> Debit Amount</th>
            <td mat-cell *matCellDef="let element"> {{element.bl_debit  | INRCurrency}} </td>
        </ng-container>

        <ng-container matColumnDef="bl_credit">
            <th mat-header-cell *matHeaderCellDef> Credit Amount</th>
            <td mat-cell *matCellDef="let element"> {{element.bl_credit  | INRCurrency}} </td>
        </ng-container>
            
        <ng-container matColumnDef="bl_balance">
            <th mat-header-cell *matHeaderCellDef> Balance </th>
            <td mat-cell *matCellDef="let element"> {{element.bl_balance  | CreditDebit}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="ledgerColumns"></tr>
        <tr class="tablerow" mat-row *matRowDef="let row; columns: ledgerColumns;"> </tr>

    </table>
    
    <mat-paginator style="margin-top: 1%; border:2px solid;" [pageSize]="5" showFirstLastButtons></mat-paginator>

</div>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>