import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { daterange, Inventory, ItemType, InvUpdate } from  '../Modules/tables';
import { Observable, Subject } from  'rxjs';

@Injectable({
  providedIn: 'root'
})

export class Finservice {

  constructor(private http: HttpClient) { }

  results:any;

  private _subject = new Subject<any>();
  changebank =  this._subject.asObservable();
  
  sendBankLedger(result){
    this._subject.next(result);
  }
  
  async getBankACNum(acnum) { 
    const bankACNum = {
      acnum: acnum,
    };
    const data = await this.http.post<any>(`${this.PHP_API_SERVER}/api/fin/readbankledger.php`, bankACNum).toPromise();
    this.results = data;
    this.sendBankLedger(this.results);
  }

  PHP_API_SERVER = "https://piyushaswani.com/backend"; 
  
  getInventoryNH(itemJSON: ItemType): Observable<Inventory[]> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/inventory/readinventoryhira.php`, itemJSON);
  }
  getInventoryTata(itemJSON: ItemType): Observable<Inventory[]> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/inventory/readinventorytata.php`, itemJSON);
  }
  updateInventory(updateJSON:InvUpdate): Observable<Inventory[]>{
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/inventory/updateinventory.php`, updateJSON);
  }


  getReceivables() : Observable<any[]>{
    return this.http.get<any[]>(`${this.PHP_API_SERVER}/api/fin/rectable.php`);
  }
  getBankBalance(): Observable<any[]>{
    return this.http.get<any[]>(`${this.PHP_API_SERVER}/api/fin/bankbalance.php`);
  }

  getTotalDeposits(dr: daterange): Observable<number> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/fin/figures/totaldeposits.php`, dr);
  }

  getTotalExpense(dr: daterange): Observable<number> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/fin/figures/totalexpense.php`, dr);
  }

  getTotalIncome(dr: daterange): Observable<number> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/fin/figures/totalincome.php`, dr);
  }

  getTotalSales(dr: daterange): Observable<number> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/fin/figures/totalsales.php`, dr);
  }

  getTotalWithdrawl(dr: daterange): Observable<number> {
    return this.http.post<any>(`${this.PHP_API_SERVER}/api/fin/figures/totalwithdrawl.php`, dr);
  }

  //Resolvers

  getMonthlyIncome(): Observable<any[]> {
    return this.http.get<any[]>(this.PHP_API_SERVER + '/api/fin/monthlyincomes.php');
  }
  getOfficePie(): Observable<number[]> {
    return this.http.get<number[]>(this.PHP_API_SERVER + '/api/fin/salesrevenueoff.php');
  }
  
  getMonthlySales(): Observable<any[]> {
    return this.http.get<any[]>(this.PHP_API_SERVER + '/api/fin/monthlysales.php');
  }

  getMonthlyAvvalSales(): Observable<any[]> {
    return this.http.get<any[]>(this.PHP_API_SERVER + '/api/fin/monthlyavval.php');
  }
  
}