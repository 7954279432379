<div class="body" style="height: 430px; overflow-y: scroll; overflow: overlay;">
    <table mat-table [dataSource]="transactions">
        
        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef> S.No. </th>
            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>

        </ng-container>

        <ng-container matColumnDef="sales_date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_date | date:'dd/MM/yyyy'}} </td>
        </ng-container>
            
        <ng-container matColumnDef="cust_name">
            <th mat-header-cell *matHeaderCellDef> Customer Name </th>
            <td mat-cell *matCellDef="let element"> {{element.cust_name}} </td>
        </ng-container>
            
        <ng-container matColumnDef="sales_item">
            <th mat-header-cell *matHeaderCellDef> Item </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_item}} </td>
        </ng-container>

        <ng-container matColumnDef="sales_quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="sales_rate">
            <th mat-header-cell *matHeaderCellDef> Rate </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_rate}} </td>
        </ng-container>

        <ng-container matColumnDef="sales_amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_amount  | INRCurrency}} </td>
        </ng-container>
            

        <ng-container matColumnDef="sales_remark">
            <th mat-header-cell *matHeaderCellDef> Remarks </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_remark}} </td>
        </ng-container>

        <ng-container matColumnDef="sales_ref">
            <th mat-header-cell *matHeaderCellDef> Ref </th>
            <td mat-cell *matCellDef="let element"> {{element.sales_ref}} </td>
        </ng-container>
            
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>