import { Component,OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, switchMap, debounceTime} from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { SalesTransaction, Inventory} from  '../Modules/tables';
import {CreatecustComponent} from '../createcust/createcust.component';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {Router} from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export interface Vehicle {
  value: string;
  viewValue: string;
}

export interface VehicleGroup {
  disabled?: boolean;
  company: string;
  vehicle: Vehicle[];
}

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css'],
  providers: [
  { provide: DateAdapter, useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],},
  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},],
})
 
export class SalesComponent implements OnInit{ 

  constructor(private service: ApiService, public dialog: MatDialog, private errorsnackBar: MatSnackBar, private successsnackBar: MatSnackBar, private router: Router) { } 

  createNewCustomer(): void {
    const dialogRef = this.dialog.open( CreatecustComponent , { width: '40vw', height: '80vh', disableClose: true, panelClass: "foo" } ); 
  }

  ngOnInit() {
    this.filteredOptions = this.salesForm.controls['customerName'].valueChanges.pipe(     // In filteredOptions array store observable of form control customerName
      startWith(""),                                                                      // Trigger event everytime form control's value changes
      debounceTime(300),                                                                  
      switchMap(value => this.doFilter(value))
    );
  }

  filteredOptions: Observable<string[]>;
  customers: any;
  customer_name: string;
  customer_of: string;
  options: string[] = ['Abba', 'Dabba', 'Jabba'];
  items: string[] = ['Avval Bricks','Meetha Bricks','Avval Tukra','Meetha Tukra', 'Godiya Bricks', 'Raanth Bricks','Sema Bricks'];
  warehouses:string[] = ['NHIU', 'TIU'];
  public pvenable = true;
  public pvdisable = false;
  disabled: boolean = true;
  public ItemPrice: number;
  inv: Inventory[];

  salesForm = new FormGroup({
    salesDate: new FormControl('', Validators.required),
    sale_of: new FormControl('', Validators.required),
    customerName: new FormControl('', Validators.required),
    itemType: new FormControl('', Validators.required),
    itemQuantity: new FormControl('', Validators.required),
    itemRate: new FormControl('', Validators.required),
    itemPrice: new FormControl('', Validators.required),
    referenceSlip: new FormControl('', Validators.required),
    vehicleNumber: new FormControl('', Validators.required),
    itemRemark: new FormControl('', Validators.required),
    warehouse: new FormControl('',Validators.required)
  });

  vehicleGroups: VehicleGroup[] = [
    {
      company: 'NHIU',
      vehicle: [
        {value: '8996', viewValue: 'Lu 2 Ta 8996'},
        {value: '7206', viewValue: 'Lu 2 Ta 7206'},
        {value: '3463', viewValue: 'Lu 3 Ta 3463'},
        {value: '365', viewValue: 'Lu 2 Ta 365'},
        {value: '7693', viewValue: 'Lu 2 Ta 7693'}
      ]
    },
  
    {
      company: 'TIU',
      vehicle: [
        {value: '3811', viewValue: 'Lu 2 Ta 3811'},
        {value: '256', viewValue: 'Lu 2 Ta 265'},
        {value: '6603', viewValue: 'Lu 3 Ta 6603'},
        {value: '2599', viewValue: 'Lu 2 Ta 2599'},
      ]
    }
  ];

  doFilter(value){                                                                  //Get customers list
    return this.service.getData().pipe(map(response =>response.filter(option => {
    return option.cust_name.indexOf(value) === 0;} )));
  }
  
  /** {value: '', disabled: true} */

  price(){
  this.ItemPrice = this.salesForm.get('itemQuantity').value * this.salesForm.get('itemRate').value;
  this.salesForm.patchValue({
    itemPrice: this.ItemPrice,
  })
  }

  customerOf(eventvalue){
    this.customer_name = eventvalue;  
    this.service.getCustomerOf(this.customer_name).subscribe(customer=>{
      this.customers = customer;
      this.customer_of = this.customers[0].cust_of;
      console.log(this.customer_of);
      this.salesForm.patchValue({
        sale_of: this.customer_of
      })
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onReset(){
    this.salesForm.reset();
    this.ngOnInit();
    this.salesForm.setValue ({
      customerName: '',
      sale_of:'',
      itemType: '',
      itemQuantity: '',
      itemRate: '',
      itemPrice: '',
      salesDate: '',
      vehicleNumber: '',
      itemRemark: '',
      referenceSlip: '',
      warehouse: '', 
    })
  }
  public errorMsg = "";

  doSalesTransaction() {
    this.service.addSalesTransaction(this.salesForm.value).subscribe( (salestransaction: SalesTransaction) => {
      this.openSuccessSnackBar('Success! Transaction is comeplete.', 'Close')
    },
    (error) => { this.openErrorSnackBar('Error occured. Transaction not complete.','Close');},
    );
    this.onReset();
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    let er_snack = this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
    er_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  openSuccessSnackBar(message: string, action: string){
    let su_snack = this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
    su_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
    });
  }

  Inventory() {
    this.service.updateInventory(this.salesForm.value).subscribe((inventory:Inventory[])=>{
    })
  }

  usePrivateVehicle(){
    this.disabled = !this.disabled;
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/salesandincome')}); 
  }

}