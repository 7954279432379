import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS} from 'date.adapter';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {DaybookService} from '../services/daybook.service';

@Component({
  selector: 'app-daybook',
  templateUrl: './daybook.component.html',
  styleUrls: ['./daybook.component.css'],
  providers: [DatePipe,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})

@Injectable({ providedIn: 'root' })

export class DaybookComponent implements OnInit {

constructor(private dbs: DaybookService, private datepipe: DatePipe) { }  

  ngOnInit() { this.hidebody = true; this.enable_button = true; }

  active = 1;
  tabname: string;
  saleof: string;
  changeevent: Event;
  childDate: Date;
  hidebody : boolean = true;
  enable_button : boolean  = true;
  show:boolean = true;
  stringDate:string;
  fetch:boolean = true;
  showdeposits:boolean = true;

  totalIncome: number;
  totalExpense: number;
  totalDeposit: number;
  totalWithdrawl: number;
  totalSales: number;

  CIH: number;

  AvvalResults: any;
  avval_office: number;
  avval_nhiu: number;
  avval_tiu: number;

  MeethaResults: any;
  meetha_office: number;
  meetha_nhiu: number;
  meetha_tiu: number;

  SemaResults: any;
  sema_office: number;
  sema_nhiu: number;
  sema_tiu: number;

  GodiyaResults: any;
  godiya_office: number;
  godiya_nhiu: number;
  godiya_tiu: number;

  RaanthResults: any;
  raanth_office: number;
  raanth_nhiu: number;
  raanth_tiu: number;

  TukraResults: any;
  tukra_office: number;
  tukra_nhiu: number;
  tukra_tiu: number;

  MeethaTukraResults: any;
  meetha_tukra_office: number;
  meetha_tukra_nhiu: number;
  meetha_tukra_tiu: number;

  cash_revenue: any;
  office_cr: number;
  nhiu_cr : number;
  tiu_cr: number;

  cheque_revenue: any;
  office_chr: number;
  nhiu_chr : number;
  tiu_chr: number;

  bank_transfers: any;
  office_bt: number;
  nhiu_bt : number;
  tiu_bt: number;

  cash_expense:any;
  office_ce:number;
  nhiu_ce:number;
  tiu_ce:number;

  filterOffice() {
    this.saleof = "Office";
    this.dbs.filterTransactionSource(this.saleof);
    this.dbs.TransactionSourceChangeEvent(this.changeevent);
    this.showdeposits = false;
  };

  filterHira() {
    this.saleof = "NHIU";
    this.dbs.filterTransactionSource(this.saleof);
    this.dbs.TransactionSourceChangeEvent(this.changeevent);
    this.showdeposits = true;
  };

  filterTata() {
    this.saleof = "TIU";
    this.dbs.filterTransactionSource(this.saleof);
    this.dbs.TransactionSourceChangeEvent(this.changeevent);
    this.showdeposits = true;
  };

  daybookFilterForm = new FormGroup({
    sDate: new FormControl(''),
  });

  sendEvent(nextevent) {                 // Method called by html to send event of date-selection/button-click
    this.dbs.newEvent(nextevent);
    this.fetch = false;
    this.show = false;
    this.hidebody = false;
  }
  
  dateSend(){

    this.enable_button = false;
    this.childDate = this.daybookFilterForm.get('sDate').value;
    this.dbs.changeFilterDate(this.childDate);
    this.stringDate = this.datepipe.transform(this.childDate, 'yyyy-MM-dd');

    const dateJSON = {
      newDate: this.stringDate,
    };
    
    this.TotalIncome(dateJSON);
    this.TotalWithdrawl(dateJSON);
    this.TotalExpense(dateJSON);
    this.TotalSales(dateJSON);
    this.TotalDeposits(dateJSON);

    this.avvalSold(dateJSON);
    this.meethaSold(dateJSON);
    this.tukraSold(dateJSON);
    this.godiyaSold(dateJSON);
    this.raanthSold(dateJSON);
    this.semaSold(dateJSON);
    this.meethaTukraSold(dateJSON);
    
    this.CashInHand(dateJSON);

    this.fetch = true;

    this.cashRevenue(dateJSON);
    this.chequeRevenue(dateJSON);
    this.bankTransfer(dateJSON);

    this.cashExpense(dateJSON);
  }

  //Figures

    CashInHand(dateJSON){
      this.dbs.getCashInHand(dateJSON).subscribe((CIH:number) => {
        this.CIH = CIH;})
    }
    
    TotalIncome(dateJSON){
      this.dbs.getTotalIncome(dateJSON).subscribe((totalIncome:number)=>{
      this.totalIncome = totalIncome;})
    }

    TotalWithdrawl(dateJSON){
      this.dbs.getTotalWithdrawl(dateJSON).subscribe((totalWithdrawl:number)=>{
        this.totalWithdrawl = totalWithdrawl;
      })
    }

    TotalExpense(dateJSON){
      this.dbs.getTotalExpense(dateJSON).subscribe((totalExpense:number)=>{
        this.totalExpense = totalExpense;
      })
    }

    TotalSales(dateJSON){
      this.dbs.getTotalSales(dateJSON).subscribe((totalSales:number)=>{
        this.totalSales = totalSales;
      })
    }

    TotalDeposits(dateJSON){
      this.dbs.getTotalDeposits(dateJSON).subscribe((totalDeposit:number)=>{
        this.totalDeposit = totalDeposit;
      })
    }

  //Figures ENDS

  // Summary

    // Sales Summary
      avvalSold(dateJSON){
        this.dbs.getAvvalSold(dateJSON).subscribe((totalAvval:any)=>{
          this.AvvalResults = totalAvval;
          this.avval_office = this.AvvalResults[0];
          this.avval_nhiu = this.AvvalResults[1];
          this.avval_tiu  = this.AvvalResults[2];
        })
      }

      meethaSold(dateJSON){
        this.dbs.getMeethaSold(dateJSON).subscribe((totalMeetha:any)=>{
          this.MeethaResults = totalMeetha;
          this.meetha_office = this.MeethaResults[0];
          this.meetha_nhiu = this.MeethaResults[1];
          this.meetha_tiu = this.MeethaResults[2];
        })
      }

      tukraSold(dateJSON){
        this.dbs.getTukraSold(dateJSON).subscribe((totalTukra:any)=>{
          this.TukraResults = totalTukra;
          this.tukra_office = this.TukraResults[0];
          this.tukra_nhiu = this.TukraResults[1];
          this.tukra_tiu = this.TukraResults[2];
        })
      }

      godiyaSold(dateJSON){
        this.dbs.getGodiyaSold(dateJSON).subscribe((totalGodiya:any)=>{
          this.GodiyaResults = totalGodiya;
          this.godiya_office = this.GodiyaResults[0];
          this.godiya_nhiu = this.GodiyaResults[1];
          this.godiya_tiu = this.GodiyaResults[2];
        })
      }

      raanthSold(dateJSON){
        this.dbs.getRaanthSold(dateJSON).subscribe((totalRaanth:any)=>{
          this.RaanthResults = totalRaanth;
          this.raanth_office =  this.RaanthResults[0];
          this.raanth_nhiu = this.RaanthResults[1];
          this.raanth_tiu = this.RaanthResults[2];
        })
      }

      semaSold(dateJSON){
        this.dbs.getSemaSold(dateJSON).subscribe((totalSema:any)=>{
          this.SemaResults = totalSema;
          this.sema_office = this.SemaResults[0];
          this.sema_nhiu = this.SemaResults[1];
          this.sema_tiu = this.SemaResults[2];
        })
      }

      meethaTukraSold(dateJSON){
        this.dbs.getMeethaTukraSold(dateJSON).subscribe((totalSema:any)=>{
          this.MeethaTukraResults = totalSema;
          this.meetha_tukra_office = this.MeethaTukraResults[0];
          this.meetha_tukra_nhiu = this.MeethaTukraResults[1];
          this.meetha_tukra_tiu = this.MeethaTukraResults[2];
        })
      }

    //Sales Summary Ends

    // Revenue summary
    cashRevenue(dateJSON){
      this.dbs.getCashRevenue(dateJSON).subscribe((cr:any)=>{
        this.cash_revenue = cr;
        this.office_cr = this.cash_revenue[0];
        this.nhiu_cr = this.cash_revenue[1];
        this.tiu_cr  = this.cash_revenue[2];
      })
    }

    chequeRevenue(dateJSON){
      this.dbs.getChequeRevenue(dateJSON).subscribe((chr:any)=>{
        this.cheque_revenue = chr;
        this.office_chr = this.cheque_revenue[0];
        this.nhiu_chr = this.cheque_revenue[1];
        this.tiu_chr  = this.cheque_revenue[2];
      })
    }

    bankTransfer(dateJSON){
      this.dbs.getBankTransfer(dateJSON).subscribe((bt:any)=>{
        this.bank_transfers = bt;
        this.office_bt = this.bank_transfers[0];
        this.nhiu_bt = this.bank_transfers[1];
        this.tiu_bt  = this.bank_transfers[2];
      })
    }

    // Revenue summary ends

    cashExpense(dateJSON){
      this.dbs.getCashExpense(dateJSON).subscribe((ce:any)=>{
        this.cash_expense = ce;
        this.office_ce = this.cash_expense[0];
        this.nhiu_ce = this.cash_expense[1];
        this.tiu_ce = this.cash_expense[2];
      })
    }
    

  // Summary

}

@Pipe({
  name:'INRCurrency'
})
export class INRCurrencyPipe implements PipeTransform {
  transform(value:number): string{

      value = Math.round(value);
      var result = value.toString().split('.');
      var lastThree = result[0].substring(result[0].length - 3);
      var otherNumbers = result[0].substring(0, result[0].length - 3);
      if (otherNumbers != '' && otherNumbers != '-')
          lastThree = ',' + lastThree;
      var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      if (result.length > 1) {
          output += "." + result[1];
      }

      return output;

  }
}
/*
export class datefilter{
  constructor(public datepipe: DatePipe) {
    this.dataSource.filterPredicate =
    (data, filter: string) => !filter || data.date.includes(filter);
    } 
    
      applyFilter(filterValue: string, event) {
        debugger;
        
        if (event.value != undefined) {
          filterValue = this.datepipe.transform(filterValue, 'dd/mm/yyyy');
        }
        this.dataSource.filter = filterValue.trim();
      }
}
*/
