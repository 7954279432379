<mat-card style="height:100%">
    
    <mat-card-title style="color:white; background-color: #0B0C12; padding:2%;">Cash Withdrawl</mat-card-title>                 
              
    <mat-card-content>

        <form [formGroup]="withdrawlForm" (ngSubmit)="doCWTransaction()">
        
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="2%" style="padding:2%;">

                <app-correct-date fxFlex [title]="'Select Date'" formControlName="withdrawlDate"></app-correct-date>
                        
                <mat-form-field fxFlex appearance="outline">
                    <mat-label> Amount Withdrawn </mat-label>
                    <input type="text" matInput formControlName="amountWithdrawn">
                </mat-form-field>    

                <div fxLayout="row" fxLayoutGap="1%">

                    <mat-form-field fxFlex appearance="outline" fxFlex="92%">
                        <input type="text" matInput placeholder="Select Bank Account" [matAutocomplete]="auto" formControlName="bankAccount">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of bankAccounts | async" [value]="option">
                                {{option.bank_name }} || {{ option.bank_acholder}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field> 

                    <button type="button" class="custbutton" (click)="createBL()" fxFlex="8%" >
                        <i class="fas fa-plus"></i>
                    </button>

                </div>

                <div fxFlex>
                    <div fxLayout="row" fxLayoutAlign="space-between end" fxFlex>

                        <button type="submit" mat-raised-button class="submitbutton" > Submit </button>
                        <button type="button" mat-raised-button class="submitbutton" (click)="onReset()"> Reset Fields </button>

                    </div>
                </div>
                
            </div>

        </form>

    </mat-card-content>

</mat-card>        