<div style="background-color: #0B0C12;color:white; padding:1%;">
    <h2 mat-dialog-title>Create New Customer</h2>
</div>

<div mat-dialog-content>

    <form [formGroup]="newBank" (ngSubmit)="addNewBank()">

        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="2%" style="margin:2%">

            <div fxLayout="row" fxLayoutGap="1%">
                
                <mat-form-field fxFlex="70" class="customer" appearance="outline">
                    <mat-label>Bank Name</mat-label>
                    <input type="text" matInput formControlName="bank_name">
                </mat-form-field> 

                <mat-form-field fxFlex="30" class="customer" appearance="outline">
                    <mat-label>Account Type</mat-label>                
                        <mat-select formControlName="account_type">
                            <mat-option *ngFor="let item of items" [value]="item" >
                                {{item}}
                            </mat-option>
                    </mat-select>
                </mat-form-field> 
            
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Account Number</mat-label>
                <input type="text" matInput formControlName="account_number" >
            </mat-form-field> 

            <mat-form-field appearance="outline">
                <mat-label>Account Holder</mat-label>
                <input type="text" matInput formControlName="account_of" >
            </mat-form-field> 

            <mat-form-field appearance="outline">
                <mat-label>Bank Balance</mat-label>
                <input type="text" matInput formControlName="bank_balance" >
                <mat-hint style="margin-top: 3%;">If amount is credit, input positive number. If amount is debit, input negative number.</mat-hint>
            </mat-form-field> 

            <div fxLayout="row" fxLayoutAlign="space-around center" style="padding-top:2%;">
                <button mat-raised-button color="warn" class="submitbutton" [disabled]="!newBank.valid"> Submit </button>
                <button type="button" mat-raised-button color="warn" class="button" (click)="onReset()"> Reset Fields </button>
            </div>

        </div>

    </form>
</div>

<div mat-dialog-actions style="margin-top: 2%; border-top: 1px solid black;">
    <button type="button" class="btn btn-outline-dark" [mat-dialog-close]="true">Close</button>
</div>