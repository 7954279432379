import { Component, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import {SalesTransaction} from '../Modules/tables'
import {DaybookService} from '../services/daybook.service';
import { company_table_name } from '../Modules/tables';

@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrls: ['./salesreport.component.css']
})
export class SalesreportComponent implements OnInit {
 
  constructor(private service: DaybookService, private datepipe: DatePipe) {  }  

  ngOnInit() {
    this.saleof = "Office";
    this.service.events$.forEach(event => this.catchEvent(event));                              // Catch event of date selection in daybook
    this.service.changeTransactionOf.forEach(newSaleOf => this.saleof = newSaleOf);
    this.service.source_change_event.forEach(change_event => this.changeSource(change_event));
  }
  
  tabledata: company_table_name;
  childDate: Date;
  date: string;
  saleof: string;
  filteredParentDate: Date;
  nextevent: Event;
  transactions: SalesTransaction[];
  filteredTransactions: SalesTransaction[];
  
  displayedColumns: string[] = ['serial', 'sales_date', 'cust_name', 'sales_item','sales_quantity', 'sales_rate', 'sales_amount','sales_remark', 'sales_ref'];

  catchEvent(event){                        //Catch event from daybook
    this.filterTransactionByDate(event);
  }

  changeSource(change_event) {
    this.getTransaction();
  }
  
  filterTransactionByDate(event) {

    this.service.filteredDate.subscribe(childDate => this.childDate = childDate);
    this.filteredParentDate = this.childDate;
    this.date = this.datepipe.transform(this.filteredParentDate, 'yyyy-MM-dd');

    this.getTransaction();

  }

  getTransaction(){

    this.tabledata = {newdate: this.date, cust_of: this.saleof}

    this.service.readSales2(this.tabledata).subscribe( (transactions: SalesTransaction[]) => {
      this.transactions = transactions;
      console.log(this.transactions);
    });

  }

//  filterTransactionBySource(){  
//    this.filteredTransactions = this.transactions.filter(filteredresult => filteredresult.sales_of == this.saleof);
//    console.log("filtered transactions is ");
//    console.log(this.transactions);
//  }
   
}