import { Component, Injectable } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Customer, CustDB } from  '../Modules/tables';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: "root"
})


@Component({
  selector: 'app-createcust',
  templateUrl: './createcust.component.html',
  styleUrls: ['./createcust.component.css']
})

export class CreatecustComponent {

  constructor(
    private service: ApiService, 
    private errorsnackBar: MatSnackBar, 
    private successsnackBar: MatSnackBar, 
    private router: Router,
    private dialogRef: MatDialogRef<CreatecustComponent>
  ) { }  

  customers:  Customer[];
  customerDB: CustDB[];
  public customer_us: any;

  cust_of: string[] = ['Office','NHIU','TIU'];

  newCustomer = new FormGroup({
      name: new FormControl('', Validators.required),
      cust_of: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      opening: new FormControl('', Validators.required),
  });


  addNewCustomer(){
    this.service.createCustomer(this.newCustomer.value).subscribe((customer: Customer)=>{
      this.openSuccessSnackBar('Success! Customer '+customer.cust_name+' is added.', 'Close')
    },
    (error) => { this.openErrorSnackBar('Error occured. Customer not addedd.','Close');},
    );
    
    this.onReset();
  };

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openErrorSnackBar(message: string, action: string) {
    let er_snack = this.errorsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'error_snackbar',
    });
    er_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
      this.closeDialog()
    });
  }

  openSuccessSnackBar(message: string, action: string){
    let su_snack = this.successsnackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'success_snackbar',
    });
    su_snack.onAction().subscribe(() => {
      setTimeout(()=>{this.reLoad();}, 2000)
      this.closeDialog()
    });
  }

  reLoad(){
    this.router.navigateByUrl( '/', {skipLocationChange: true } ).then(() => {
      this.router.navigateByUrl('/salesandincome')}); 
  }

  onReset(){
    this.newCustomer.reset();
  }

  closeDialog(){
    this.dialogRef.close();
  }

}

//@Pipe({ name: 'removeUnderscore' })
  
//export class RemoveUnderscorePipe implements PipeTransform {
//  transform(value: string, args?: any): string {
//    return value.replace(/ /g, "_");
//  }
//}