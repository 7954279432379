import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deposits-and-withdrawl',
  templateUrl: './deposits-and-withdrawl.component.html',
  styleUrls: ['./deposits-and-withdrawl.component.css']
})
export class DepositsAndWithdrawlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
