<h3 mat-dialog-title>Update/Corretion on ledger of - <span style="color: red;">{{data.name}}</span></h3>

<div mat-dialog-content>

    <form [formGroup]="ledgercorrection" (ngSubmit)="editLedger()">

        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="2%" style="margin:2%">

            <app-correct-date fxFlex="92%" [title]="'Select Date'" formControlName="date"></app-correct-date>

            <mat-form-field appearance="outline" fxFlex="90">
                <mat-label>Cust Name:</mat-label>
                <input type="text" readonly matInput formControlName="name">
            </mat-form-field> 
            
            <mat-form-field appearance="outline">
                <mat-label>Particular</mat-label>
                <input type="text" matInput formControlName="part">
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start center" style="padding-top:2%;" fxLayoutGap="2%">
            
                <mat-form-field appearance="outline" fxFlex="60">
                    <mat-label>Amount</mat-label>
                    <input type="text" matInput formControlName="amount">
                </mat-form-field> 

                <mat-form-field appearance="outline" fxFlex="40">
                    <mat-label>Transaction type</mat-label>
                    <mat-select formControlName="dc">
                            <mat-option *ngFor="let choice of dc" [value]="choice">
                                {{choice}}
                            </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="space-around center" style="padding-top:2%;">
                <button mat-raised-button color="warn" class="submitbutton" > Submit </button>
                <button type="button" mat-raised-button color="warn" class="submitbutton" (click)="onReset()"> Reset Fields </button>
            </div>

        </div>

    </form>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>