import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { Customer, CLedger } from '../Modules/tables';
import { trigger, style, transition, animate} from '@angular/animations';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { formatDate} from '@angular/common';
import { ExcelService} from '../services/excel.service';
import { LedgerService } from '../services/ledger.service';

import {MatDialog } from '@angular/material/dialog';

import { LedgerCorrectionComponent } from '../ledger-correction/ledger-correction.component';

@Component({
    selector: 'app-customerledgers',
    templateUrl: './customer-ledgers.component.html',
    styleUrls: ['./customer-ledgers.component.css'],
    animations: [
      trigger('slideLeftToRight', [
        transition(':enter', [
          style({transform: 'translateX(100%)'}),
          animate('200ms 300ms ease-in', style({transform: 'translateX(0%)'}))
        ]),
        transition(':leave', [
          animate('200ms ease-in', style({transform: 'translateX(100%)'}))
        ])
      ]),
      trigger('slideRightToLeft', [
        transition(':enter', [
          style({transform: 'translateX(-100%)'}),
          animate('200ms 300ms ease-in', style({transform: 'translateX(0%)'}))
        ]),
        transition(':leave', [
          animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
        ])
      ])
    ]
})
  
export class CustomerLedgersComponent implements OnInit, AfterViewInit{

  ngOnInit(){
    this.deflistfilename = 'Defaulter List '+this.today;
  }

  constructor(private service: LedgerService, private excelService:ExcelService, public dialog: MatDialog) { }

  custname: string;

  openDialog(): void {
    const dialogRef = this.dialog.open(LedgerCorrectionComponent, {
      width: '500px',
      data: {name: this.custname},
    });
    
  }
  
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  CustLedgerDataSource = new MatTableDataSource<CLedger>();
  private paginator: MatPaginator

  setDataSourceAttributes() {
    this.CustLedgerDataSource.paginator = this.paginator;
  }

  cust_of_circle: string;
  deflistfilename: string;
  today =  formatDate(new Date(), 'dd-MM-YYYY', 'en', '+0530');
  customer_of: string;
  isVisible = true;
  customerName: string;
  ledgerBalance: number;
  showDef:boolean = false;
  printDefButton:boolean = true;
 
  element: HTMLElement;
  custList: Customer[];
  emptyDataSource: Customer[];
  custLedger: CLedger[];
  ledgerColumns: string[] = ['serial', 'cl_date', 'cl_ref', 'cl_part', 'cl_debit', 'cl_credit', 'cl_balance'];
  customerList: string[] = ['serialnum', 'cust_name', 'cust_number','cust_address', 'cust_of', 'current_balance'];
  alphabet: string[] = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

  selectedLetter({value}){
    console.log(value);
    this.beginsWith = value;
    this.service.showLedgerCustList(this.beginsWith).subscribe((custList: Customer[])=>{
      this.custList = custList;
      this.dataSource.data = custList;
    })
  }

  public beginsWith = "";
  public custDBName;
  public defaulterList;
  public ledgerFileName;
  dataSource = new MatTableDataSource<Customer>()

  showCustList(myBtn: HTMLButtonElement){
    this.beginsWith = myBtn.innerHTML;
    console.log(this.beginsWith);
    this.service.showLedgerCustList(this.beginsWith).subscribe((custList: Customer[])=>{
        this.custList = custList;
        this.dataSource.data = custList;
        console.log(this.custList);})
  }

  openLedger(row){
      this.isVisible = !this.isVisible;
      console.log(row);
      this.customerName = row.cust_name;
      this.custname = row.cust_name;
      this.ledgerFileName = 'Ledger of '+this.customerName+' on '+this.today
      console.log(this.customerName);
      this.custDBName = row.cust_name.replace(/ /g, "_").toLowerCase();

      const ledgerJSON = {
        dbname: this.custDBName,
      };
      console.log(ledgerJSON);
      
      this.service.showCustLedger(ledgerJSON).subscribe((custLedger: CLedger[])=>{
      this.custLedger = custLedger;
      this.CustLedgerDataSource.data = custLedger;
      console.log(this.CustLedgerDataSource.data);
      })
      
      this.service.ledgerBalance(ledgerJSON).subscribe((ledgerBalance:number)=>{
        this.ledgerBalance = ledgerBalance;
        console.log(ledgerBalance);
      })
      
      this.customerOf(this.customerName);
  }

  customerOf(cust_name){
    const cn = {
      custname: cust_name,
    };

    console.log(cn);
    this.service.getCustOf(cn).subscribe((customerof:string)=>{
      this.customer_of = customerof;
      if(this.customer_of == 'Office'){
        this.cust_of_circle = 'OFF';
      }
      else{
        this.cust_of_circle = this.customer_of;
      }      
    })
  }

  showDefaulters(){
    this.service.getDefaulters().subscribe((defaulterList: Customer[])=>{
      this.defaulterList = defaulterList;
      this.dataSource.data = defaulterList;
    })
    this.showDef = !this.showDef;
    this.printDefButton = !this.printDefButton;
  }

  showIndex(){
    this.showDef = !this.showDef;
    this.printDefButton = !this.printDefButton;
    this.dataSource.data = this.emptyDataSource;
    console.log(this.emptyDataSource);
  }

  goBack(){
    this.isVisible = !this.isVisible;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportDefaulters():void {
    this.excelService.exportAsExcelFile(this.defaulterList, this.deflistfilename);
  }
  exportLedger():void {
    this.excelService.exportAsExcelFile(this.custLedger, this.ledgerFileName);
  }
}

@Pipe({
  name: 'CreditDebit'
})

export class CreditDebit implements PipeTransform {
  
  transform(value:number): string{
    value = Math.round(value);
    var type: string;

    if(value<0){ type = "Dr"; value = value*-1;}
    else{ type = "Cr"; }
    
    var result = value.toString().split('.');
    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    
    if (otherNumbers != '' && otherNumbers != '-')
        lastThree = ',' + lastThree;
    var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    
    if (result.length > 1) {
        output += "." + result[1];
    }
    output = 'Rs. ' + output + ' ' + type;

    return output;

  }
}