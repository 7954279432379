import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Customer} from '../Modules/tables';
import { ApiService } from '../services/api.service';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  ngOnInit() {}

  
constructor(private service: ApiService) { }


CustomersDatasource = new MatTableDataSource<Customer>();
private paginator: MatPaginator

@ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
  this.paginator = mp;
  this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.CustomersDatasource.paginator = this.paginator;
  }

  customers: Customer[];
  customerList: string[] = ['serialnum', 'name', 'number', 'address', 'custof', 'balance'];

  showIndex(){
    console.log('show index')
  }

}

@Pipe({
  name: 'CreditDebit'
})
export class CreditDebit implements PipeTransform{
  
  transform(value:number): string{
    value = Math.round(value);
    var type: string;

    if(value<0){ type = "Dr"; value = value*-1;}
    else{ type = "Cr"; }
    
    var result = value.toString().split('.');
    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    
    if (otherNumbers != '' && otherNumbers != '-')
        lastThree = ',' + lastThree;
    var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    
    if (result.length > 1) {
        output += "." + result[1];
    }
    output = output + ' NPR ' + type;

    return output;

}
}