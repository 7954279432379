<mat-card class="sales-card">

    <mat-card-title style="color:white; background-color: #0B0C12; padding:2%;">Sales</mat-card-title>
   
    <mat-card-content>
        
        <form name="form" [formGroup]="salesForm" (ngSubmit)="doSalesTransaction()">
                
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1%" style="padding:2%;">
            
                <div class="DATE" fxLayout="row" fxLayoutGap="1%"> 

                    <app-correct-date [title]="'Select Date'" formControlName="salesDate" fxFlex="50"></app-correct-date> 

                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Sale Of</mat-label>
                        <input matInput formControlName="sale_of" [readonly]="true">
                    </mat-form-field>

                </div>
            
                <div class="CUST">
                    
                    <div fxLayout="row" fxLayoutGap="1%">
                        
                        <mat-form-field class="customer" appearance="outline" fxFlex="92%">
                            <mat-label>Select Customer</mat-label>
                            <input type="text" matInput [matAutocomplete]="auto" formControlName="customerName">
                                <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="customerOf($event.option.value)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.cust_name">
                                        {{option.cust_name}}
                                    </mat-option>
                                </mat-autocomplete>
                        </mat-form-field>

                        <button type="button" class="custbutton" (click)="createNewCustomer()" fxFlex="8%" >
                            <i class="fas fa-plus"></i>
                        </button>
                        
                    </div>
                </div>

                <div class="items">
                    <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="center center">

                        <mat-form-field class="itemRow" appearance="outline" fxFlex="50">
                            <mat-label>Item Type</mat-label>
                                <mat-select formControlName="itemType">
                                    <mat-option *ngFor="let item of items" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                        </mat-form-field>

                            <!--*** Quantity ****-->

                        <mat-form-field class="itemFields" appearance="outline" fxFlex="25">
                            <mat-label>Quantity</mat-label>
                            <input type="text" matInput formControlName="itemQuantity" >
                        </mat-form-field>

                            <!--*** Rate ****-->

                        <mat-form-field class="itemFields" appearance="outline" fxFlex="25">
                            <mat-label>Rate</mat-label>
                            <input type="text" matInput formControlName="itemRate" (blur)="price()">
                        </mat-form-field>
                        
                    </div>
                </div>

                <div class="price">
                    <div fxLayout="row" fxLayoutGap="1%">

                        <mat-form-field class="price" appearance="outline" fxFlex="75">
                            <mat-label>Price</mat-label>
                            <input type="text" matInput formControlName="itemPrice" [readonly]="true">
                        </mat-form-field>

                        <mat-form-field class="refslip" appearance="outline" fxFlex="25">
                            <mat-label>Ref Slip No.</mat-label>
                            <input type="text" matInput formControlName="referenceSlip">
                        </mat-form-field>
                        
                    </div>
                </div>    

                <div class="vehicle">
                    
                    <div fxLayout="row" fxLayoutGap="1%">
                        
                        <mat-form-field class = "vehicleRow1" *ngIf="disabled; else customerVehicle" appearance="outline" fxFlex="50">
                            <mat-label>Company Vehicle</mat-label>
                                <mat-select formControlName = "vehicleNumber" >
                                    <mat-option> -- None -- </mat-option>
                                        <mat-optgroup *ngFor="let group of vehicleGroups" [label]="group.company" [disabled]="group.disabled">
                                            <mat-option *ngFor="let vehicle of group.vehicle" [value]="vehicle.viewValue">
                                                {{vehicle.viewValue}}
                                            </mat-option>
                                        </mat-optgroup>
                                </mat-select>
                        </mat-form-field>

                        <ng-template #customerVehicle>
                            <mat-form-field  class = "vehicleRow1" appearance="outline" fxFlex="50">
                                <mat-label>Customer Vehicle</mat-label>
                                <input type="text" matInput formControlName = "vehicleNumber" > 
                            </mat-form-field>
                        </ng-template>

                        <button type="button" mat-raised-button class="submitbutton" (click)="usePrivateVehicle()" fxFlex="50"> 
                            <span *ngIf="disabled">Use Customer Vehicle</span>
                            <span *ngIf="!disabled">Use Company Vehicle</span>
                        </button>

                    </div>

                </div>

                <div class="remarks" fxLayout="row" fxLayoutGap="1%">

                    <mat-form-field class="remarks" appearance="outline" fxFlex="50">
                        <mat-label>Order Remarks</mat-label>
                        <input type="text" matInput formControlName="itemRemark">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Warehouse</mat-label>
                        <mat-select formControlName="warehouse">
                            <mat-option *ngFor="let choice of warehouses" [value]="choice">
                                {{choice}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>    

                <div class="submit">
                    <div fxLayout="row" fxLayoutAlign="space-around center">
                        <button mat-raised-button class="submitbutton" (click)="Inventory()" [disabled]="!salesForm.valid"> Submit </button>
                        <button type="button" mat-raised-button class="submitbutton" (click)="onReset()"> Reset Fields </button>
                    </div>
                </div>

            </div>
        </form>
    </mat-card-content>
    
</mat-card>